import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { Checkbox, IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import AddIcon from "@mui/icons-material/Add";

import { validateCharacters } from "../../../../shared/helper/validations";
import { isEqual } from "lodash";

export default function EdgeConditions({ ...props }) {
  const [conditions, setConditions] = React.useState([]);
  const [dirty, setDirty] = React.useState([]);
  const [errors, setErrors] = React.useState([]);
  const { t } = useTranslation("edge");
  const userPermissions = useMemo(
    () => props.userPermissions,
    [props.userPermissions],
  );

  const operations = [
    { name: "=", value: "eq" },
    { name: "!=", value: "neq" },
    { name: ">", value: "gt" },
    { name: "<", value: "lt" },
    { name: ">=", value: "egt" },
    { name: "<=", value: "elt" },
    { name: ".length >=", value: "cgt" },
    { name: ".length <=", value: "clt" },
  ];

  useEffect(() => {
    setConditions(props.conditions);
    let initialDirty = Array(props.conditions.length);
    if (!isEqual(props.conditions, [{ op: "eq" }])) {
      initialDirty = Array(props.conditions.length)
        .fill()
        .map(() => ({
          prop: true,
          value: true,
        }));
      validate(props.conditions, initialDirty, true);
    } else {
      initialDirty = Array(props.conditions.length)
        .fill()
        .map(() => ({
          prop: false,
          value: false,
        }));

      const initialErrors = Array(props.conditions.length)
        .fill()
        .map(() => ({
          prop: "",
          value: "",
        }));
      setErrors(initialErrors);
    }
    setDirty(initialDirty);
  }, [props.conditions]);

  function updateProp(e, index) {
    let current = [...conditions];
    current[index].prop = e.target.value;
    setConditions(current);

    let currentDirty = [...dirty];
    currentDirty[index].prop = true;
    setDirty(currentDirty);

    validate(current, currentDirty);
  }

  async function updateOp(e, index) {
    let current = [...conditions];
    current[index].op = e.target.value;
    setConditions(current);
    validate(current, dirty);
  }

  function handleIsParamsCheck(e, index) {
    let current = [...conditions];
    current[index].itsParams = e.target.checked;
    setConditions(current);

    let currentDirty = [...dirty];
    currentDirty[index].value = true;
    setDirty(currentDirty);

    validate(current, currentDirty);
  }

  function updateValue(e, index) {
    let current = [...conditions];
    current[index].value = e.target.value;
    setConditions(current);

    let currentDirty = [...dirty];
    currentDirty[index].value = true;
    setDirty(currentDirty);

    validate(current, currentDirty);
  }

  function handleAddCondition() {
    let current = [...conditions];
    current.push({ op: "eq" });
    setConditions(current);

    let currentDirty = [...dirty];
    currentDirty.push({ prop: false, value: false });
    setDirty(currentDirty);

    let currentErrors = [...errors];
    currentErrors.push({ prop: "", value: "" });
    setErrors(currentErrors);

    validate(current, currentDirty);
  }

  function handleRemoveCondition() {
    if (conditions.length > 1) {
      let current = [...conditions];
      current.pop();
      setConditions(current);

      let currentDirty = [...dirty];
      currentDirty.pop();
      setDirty(currentDirty);

      let currentErrors = [...errors];
      currentErrors.pop();
      setErrors(currentErrors);

      validate(current, currentDirty);
    }
  }

  function validate(currentConditions, currentDirty, initial = false) {
    let valid = true;
    let currentErrors = currentDirty.map((dirty, index) => {
      const condition = currentConditions[index];
      const error = { prop: "", value: "" };

      if (!validateCharacters(condition.prop)) {
        error.prop = t("validateValue");
        valid = false;
      }

      if (!validateCharacters(condition.value, "/_-")) {
        error.value = t("validateValue");
        valid = false;
      }

      return error;
    });

    setErrors(currentErrors);

    if (currentConditions.length === 0) {
      valid = false;
    } else {
      for (const condition of currentConditions) {
        if (!condition.op) {
          valid = false;
          break;
        }
      }
    }

    if (initial) {
      props.conditionsUpdate(
        currentConditions,
        valid,
        areDirtyFieldsValid(currentDirty, currentErrors),
      );
    }
  }

  const areDirtyFieldsValid = (dirtyFieldsArray, errorsArray) => {
    return dirtyFieldsArray.some((dirtyFields, index) => {
      const errors = errorsArray[index];
      return Object.keys(dirtyFields).some((key) => {
        const isDirty = dirtyFields[key];
        const hasError = errors[key] !== "";
        return isDirty && hasError;
      });
    });
  };

  return (
    <div>
      {conditions.map((item, index) => (
        <div key={index} className="condition">
          <div className="condition-header">
            <h3 className="condition-title">
              {t("condition")} {index + 1}
            </h3>
            {index === 0 && (
              <div className="condition-buttons">
                <Tooltip
                  title={
                    !userPermissions
                      ? t("noPermissionTooltip")
                      : t("tooltipConditionAdd")
                  }>
                  <span>
                    <IconButton
                      id="add-condition-button"
                      onClick={handleAddCondition}
                      disabled={!userPermissions}
                      style={{ cursor: "pointer" }}>
                      <AddIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    !userPermissions
                      ? t("noPermissionTooltip")
                      : t("tooltipConditionDelete")
                  }>
                  <span>
                    <IconButton
                      id="remove-condition-button"
                      disabled={!userPermissions}
                      onClick={handleRemoveCondition}
                      style={{ cursor: "pointer" }}>
                      <RemoveIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
          <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
            <TextField
              id={`condition-property-${index}`}
              className="condition-property"
              label={t("conditinProperty")}
              variant="standard"
              disabled={!userPermissions}
              multiline
              fullWidth
              value={item.prop || ""}
              onChange={(e) => updateProp(e, index)}
              error={dirty[index].prop && Boolean(errors[index]?.prop)}
              helperText={dirty[index].prop ? errors[index]?.prop : ""}
            />
          </Tooltip>
          <br />
          <InputLabel shrink>{t("conditinOperator")}</InputLabel>
          <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
            <Select
              id={`condition-operator-${index}`}
              className="condition-operator"
              fullWidth
              disabled={!userPermissions}
              value={item.op || operations[0].value}
              onChange={(e) => updateOp(e, index)}>
              {operations.map((item, index) => (
                <MenuItem name={item.name} value={item.value} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Tooltip>
          <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "10px",
              }}>
              <TextField
                id={`condition-value-${index}`}
                className="condition-value"
                label={t("conditinValue")}
                variant="standard"
                multiline
                disabled={!userPermissions}
                fullWidth
                value={item.value || ""}
                onChange={(e) => updateValue(e, index)}
                error={dirty[index].value && Boolean(errors[index]?.value)}
                helperText={dirty[index].value ? errors[index]?.value : ""}
              />
              <div
                style={{
                  display: "flex",
                  width: "30%",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "10px",
                }}>
                <Checkbox
                  checked={item.itsParams || false}
                  onChange={(e) => handleIsParamsCheck(e, index)}
                  disabled={!userPermissions}
                  size="small"
                />
                <span style={{ fontSize: "12px" }}>{t("itsAParams")}</span>
              </div>
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
}

EdgeConditions.propTypes = {
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      prop: PropTypes.string,
      op: PropTypes.string,
      value: PropTypes.string,
      itsParams: PropTypes.bool,
    }),
  ).isRequired,
  userPermissions: PropTypes.bool.isRequired,
  conditionsUpdate: PropTypes.func.isRequired,
};
