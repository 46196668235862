const permissionsDefaultRoot = [
  {
    name: "intents",
    fullAccess: true,
  },
  {
    name: "noMatch",
    fullAccess: true,
  },
  {
    name: "noMatchIgnore",
    fullAccess: true,
  },
  {
    name: "pages",
    fullAccess: true,
  },
  {
    name: "file",
    fullAccess: true,
  },
  {
    name: "endpoints",
    fullAccess: true,
  },
  {
    name: "agents",
    fullAccess: true,
  },
  {
    name: "workingDays",
    fullAccess: true,
  },
  {
    name: "routes",
    fullAccess: true,
  },
  {
    name: "chattigoTable",
    fullAccess: true,
  },
  {
    name: "clients",
    fullAccess: true,
  },
  {
    name: "campaigns",
    fullAccess: true,
  },
  {
    name: "clients2",
    fullAccess: true,
  },
  {
    name: "config",
    fullAccess: true,
  },
  {
    name: "users",
    fullAccess: true,
  },
  {
    name: "permissions",
    fullAccess: true,
  },
  {
    name: "tags",
    fullAccess: true,
  },
  {
    name: "categoryTags",
    fullAccess: true,
  },
  {
    name: "data",
    fullAccess: true,
  },
  {
    name: "flows",
    fullAccess: true,
  },
  {
    name: "translations",
    fullAccess: true,
  },
  {
    name: "report",
    fullAccess: true,
  },
  {
    name: "holidays",
    fullAccess: true,
  },
  {
    name: "v2",
    fullAccess: true,
  },
  {
    name: "status",
    fullAccess: true,
  },
  {
    name: "clientsExportImport",
    fullAccess: true,
  },
  {
    name: "monitoring",
    fullAccess: true,
  },
  {
    name: "passwordChange",
    fullAccess: true,
  },
  {
    name: "aiTraining",
    fullAccess: true,
  },
  {
    name: "train",
    fullAccess: true,
  },
];

const permissionsDefaultUser = [
  {
    name: "intents",
    fullAccess: true,
  },
  {
    name: "noMatch",
    fullAccess: true,
  },
  {
    name: "noMatchIgnore",
    fullAccess: true,
  },
  {
    name: "pages",
    fullAccess: true,
  },
  {
    name: "file",
    fullAccess: true,
  },
  {
    name: "endpoints",
    fullAccess: true,
  },
  {
    name: "agents",
    fullAccess: true,
  },
  {
    name: "workingDays",
    fullAccess: true,
  },
  {
    name: "routes",
    fullAccess: null,
  },
  {
    name: "chattigoTable",
    fullAccess: null,
  },
  {
    name: "clients",
    fullAccess: null,
  },
  {
    name: "campaigns",
    fullAccess: null,
  },
  {
    name: "clients2",
    fullAccess: null,
  },
  {
    name: "config",
    fullAccess: null,
  },
  {
    name: "users",
    fullAccess: null,
  },
  {
    name: "permissions",
    fullAccess: null,
  },
  {
    name: "tags",
    fullAccess: true,
  },
  {
    name: "categoryTags",
    fullAccess: true,
  },
  {
    name: "data",
    fullAccess: false,
  },
  {
    name: "flows",
    fullAccess: true,
  },
  {
    name: "translations",
    fullAccess: null,
  },
  {
    name: "report",
    fullAccess: true,
  },
  {
    name: "holidays",
    fullAccess: true,
  },
  {
    name: "v2",
    fullAccess: null,
  },
  {
    name: "status",
    fullAccess: null,
  },
  {
    name: "clientsExportImport",
    fullAccess: true,
  },
  {
    name: "monitoring",
    fullAccess: null,
  },
  {
    name: "passwordChange",
    fullAccess: null,
  },
  {
    name: "aiTraining",
    fullAccess: null,
  },
  {
    name: "train",
    fullAccess: true,
  },
];

const permissionsDefaultApi = [
  {
    name: "intents",
    fullAccess: null,
  },
  {
    name: "noMatch",
    fullAccess: null,
  },
  {
    name: "noMatchIgnore",
    fullAccess: null,
  },
  {
    name: "pages",
    fullAccess: null,
  },
  {
    name: "file",
    fullAccess: null,
  },
  {
    name: "endpoints",
    fullAccess: null,
  },
  {
    name: "agents",
    fullAccess: null,
  },
  {
    name: "workingDays",
    fullAccess: null,
  },
  {
    name: "routes",
    fullAccess: null,
  },
  {
    name: "chattigoTable",
    fullAccess: null,
  },
  {
    name: "clients",
    fullAccess: null,
  },
  {
    name: "campaigns",
    fullAccess: null,
  },
  {
    name: "clients2",
    fullAccess: null,
  },
  {
    name: "config",
    fullAccess: null,
  },
  {
    name: "users",
    fullAccess: null,
  },
  {
    name: "permissions",
    fullAccess: null,
  },
  {
    name: "tags",
    fullAccess: null,
  },
  {
    name: "categoryTags",
    fullAccess: null,
  },
  {
    name: "data",
    fullAccess: true,
  },
  {
    name: "flows",
    fullAccess: null,
  },
  {
    name: "translations",
    fullAccess: null,
  },
  {
    name: "report",
    fullAccess: null,
  },
  {
    name: "holidays",
    fullAccess: null,
  },
  {
    name: "v2",
    fullAccess: null,
  },
  {
    name: "status",
    fullAccess: null,
  },
  {
    name: "clientsExportImport",
    fullAccess: null,
  },
  {
    name: "monitoring",
    fullAccess: null,
  },
  {
    name: "passwordChange",
    fullAccess: null,
  },
  {
    name: "aiTraining",
    fullAccess: null,
  },
  {
    name: "train",
    fullAccess: null,
  },
];

module.exports = {
  permissionsDefaultRoot,
  permissionsDefaultUser,
  permissionsDefaultApi,
};
