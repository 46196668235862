import React, { useMemo, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { Draggable } from "@smooth-dnd/react";
import { Paper, TextField, ButtonGroup, Button } from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";



const SortableItem = ({ text, index, updateItem, deleteItem, error }) => {
  const [localText, setLocalText] = useState(text);
  const [charCount, setCharCount] = useState(text.length);
  const { t } = useTranslation("sortableItem");

  const debouncedUpdate = useMemo(
    () =>
      debounce((value) => {
        updateItem(index, value);
      }, 300),
    [updateItem, index],
  );

  const style = useMemo(
    () => ({
      display: "flex",
      alignItems: "center",
      padding: "10px",
      marginBottom: "5px",
      backgroundColor: "cardQuickReply.background",
    }),
    [error],
  );

  const handleDelete = useCallback(() => {
    deleteItem(index);
  }, [deleteItem, index]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(text);
  }, [text]);

  const handleChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      setLocalText(newValue);
      setCharCount(newValue.length);
      debouncedUpdate(newValue);
    },
    [debouncedUpdate],
  );

  React.useEffect(() => {
    setLocalText(text);
    setCharCount(text.length);
  }, [text]);

  // Cleanup del debounce
  React.useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  return (
    <Draggable handle=".drag-handle">
      <Paper sx={style}>
        <DragHandleIcon
          className="drag-handle"
          style={{ cursor: "grab", marginRight: "10px" }}
        />
        <TextField
          id={`text-dnd-item-${index}`}
          multiline
          minRows={3}
          value={localText}
          onChange={handleChange}
          variant="outlined"
          size="small"
          style={{ flexGrow: 1, marginRight: "10px" }}
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
          InputProps={{
            style: { fontSize: "0.8rem" },
          }}
          error={error}
          helperText={error ? t("errorLengthValidation") : ""}
        />
        <ButtonGroup
          orientation="vertical"
          variant="text"
          style={{ marginBottom: "10px" }}>
          <Button onClick={handleCopy} id={`text-dnd-copy-button-${index}`}>
            <ContentCopyIcon sx={{ color: "modalIconButton.color" }} />
          </Button>
          <Button onClick={handleDelete} id={`text-dnd-delete-button-${index}`}>
            <DeleteIcon sx={{ color: "deleteIcon.color" }} />
          </Button>
        </ButtonGroup>
      </Paper>
      <div style={{ textAlign: "right", fontSize: "0.8rem", marginTop: "5px", marginBottom: "5px" }}>
        {charCount}
      </div>
    </Draggable>
  );
};

SortableItem.propTypes = {
  text: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  updateItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

SortableItem.displayName = "SortableItem";

export default React.memo(SortableItem);
