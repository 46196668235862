import React, { useState } from "react";
import { VictoryAxis, VictoryChart, VictoryTooltip, VictoryBar } from "victory";
import Paper from "@mui/material/Paper";
import { get } from "../../shared/http/httpService";
import ChartTheme from "../theme/theme";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import DateRange from "../../shared/components/DateRange";
import dayjs from "dayjs";
import Container from "@mui/material/Container";
import { mkConfig, generateCsv, download } from "export-to-csv";

import { acumulateObjectTalk } from "../../shared/helper/validations";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ChannelSelect from "../../shared/components/ChannelSelect";

const useStyles = styled(() => ({
  container: {
    display: "flex",
    height: "100vh",
  },
  zIndex0: {
    zIndex: "0",
  },
  buttonContainer: {
    alignSelf: "center",
    display: "flex",
  },
  button: {
    height: "40px",
    width: "100px",
    backgroundColor: "#D9D9D9",
    color: "black",
    borderRadius: "5px",
    marginRight: "5px",
    marginLeft: "20px",
  },
}));

const TalkTime = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  let chartTheme = ChartTheme();
  const [valid, setValid] = useState(true);
  const [total, setTotal] = useState(0);
  const [talkTimes, setTalkTimes] = useState([]);
  const [totalTalks, setTotalTalks] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [fileName, setFileName] = useState(
    t("analytics:talkTimeFile") +
      getParsedDate(startDate) +
      t("analytics:to") +
      getParsedDate(endDate),
  );
  const [channelsSelected, setChannelsSelected] = useState([]);

  const prepareDataForExport = (tableData) => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
      filename: fileName,
    });
    const csvData = tableData.map((item) => {
      let channel;
      switch (item[2]) {
        case "WHATSAPP":
          channel = "WHATSAPP";
          break;
        case "WEB":
          channel = "WEB";
          break;
        case "MESSENGER":
          channel = "FACEBOOK";
          break;
        case "INSTAGRAMDIRECT":
          channel = "INSTAGRAM";
          break;
        case "msteams":
          channel = "TEAMS";
          break;
        default:
          channel = "WEBCHAT";
          break;
      }
      return {
        [t("talktime:minutes")]: item[0],
        [t("analytics:count")]: item[1],
        [t("talktime:channel")]: channel,
      };
    });
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig, fileName)(csv);
  };

  const fetchTalkTimes = async () => {
    try {
      const talkTimesTmp = await get("/report/talkTime", {
        startDate: dayjs(startDate).toDate(),
        endDate: dayjs(endDate).toDate(),
        timezone: startDate.format("Z"),
        channelsSelected: channelsSelected,
      });

      const arr2 = [];
      const arr = [];

      channelsSelected.forEach((channel) => {
        const result = [];
        talkTimesTmp.map((talkTime) => {
          if (channel === talkTime[2]) {
            result.push(talkTime[1]);
            return {
              x: talkTime[1],
            };
          }
        });
        if (result.length === 0) return;
        const arrayResult = result.reduce((acum, item) => {
          // eslint-disable-next-line no-sequences
          return acum[item] ? (acum[item] += 1) : (acum[item] = 1), acum;
        }, {});
        Object.keys(arrayResult).forEach((key) => {
          const object = { x: key, y: arrayResult[key] };
          arr.push(object);
        });

        Object.keys(arrayResult).forEach((key) => {
          arr2.push([Number(key), arrayResult[key], channel]);
        });
      });

      const arrayTalkTimes = acumulateObjectTalk(arr);

      setTotalTalks(arr2.sort((a, b) => a[0] - b[0]));
      setTotal(talkTimesTmp.length);
      setTalkTimes(arrayTalkTimes.sort((a, b) => a.x - b.x));
    } catch (error) {
      if (error.status === 202) {
        // Query is in progress, display a snackbar message
        enqueueSnackbar(t("analytics:timeoutHandlingReport"), {
          variant: "error",
        });
      } else {
        // Handle other errors
        console.error("Error fetching talk time:", error);
        throw error;
      }
    }
  };

  function handleDate(dates) {
    if (dates.startDate !== null && dates.endDate !== null && dates.valid) {
      setValid(dates.valid);
      setStartDate(dates.startDate);
      let newEndDate = new Date(dates.endDate);

      setEndDate(newEndDate);

      setFileName(
        t("analytics:talkTimeFile") +
          t("analytics:from") +
          getParsedDate(dates.startDate) +
          t("analytics:to") +
          getParsedDate(newEndDate),
      );
    } else {
      setValid(dates.valid);
    }
  }

  function getParsedDate(date) {
    return dayjs(date).format("DD-MM-YYYY");
  }

  return (
    <Container maxwidth="lg">
      <Paper
        style={{
          marginBottom: "15px",
          paddingTop: "10px",
          paddingBottom: "15px",
          paddingLeft: "5px",
        }}>
        <Typography variant="h4">{t("analytics:talkTime")}</Typography>
        <br></br>
        <Grid container justify="space-around" alignItems="center">
          <DateRange
            startDate={startDate}
            endDate={endDate}
            onDateSelected={handleDate}
          />
          <ChannelSelect setChannels={setChannelsSelected} />
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginLeft: "10px",
            }}>
            <Button
              id="talk-time-fetch"
              className={classes.button}
              variant="contained"
              color="default"
              size="medium"
              disabled={!valid || channelsSelected.length === 0}
              sx={{
                height: "40px",
                width: "130px",
                borderRadius: "5px",
              }}
              onClick={fetchTalkTimes}>
              {t("talktime:fetch")}
            </Button>
            <Button
              id="talk-time-download"
              className={classes.button}
              variant="contained"
              color="default"
              disabled={
                totalTalks.length === 0 || channelsSelected.length === 0
              }
              size="medium"
              onClick={() => {
                prepareDataForExport(totalTalks);
              }}
              sx={{
                height: "40px",
                width: "130px",
                borderRadius: "5px",
              }}>
              {t("conversationPerDay:download")}
            </Button>
          </div>
        </Grid>
        <VictoryChart
          fixLabelOverlap={true}
          responsive={false}
          height={500}
          width={800}
          animate={{
            duration: 500,
            onLoad: { duration: 200 },
          }}
          theme={chartTheme}
          domainPadding={{ x: 80 }}
          padding={80}>
          <VictoryBar
            barRatio={1}
            cornerRadius={{ top: 3 }}
            style={{
              data: {
                fill: "#B474DC",
              },
              labels: {
                fontSize: 10,
                fill: "black",
              },
            }}
            labels={({ datum }) =>
              datum.y === 0
                ? "0"
                : `conversations: ${datum.y}\n Time: ${datum.x}`
            }
            alignment="middle"
            labelComponent={
              <VictoryTooltip
                cornerRadius={1}
                pointerLength={0}
                flyoutStyle={{
                  stroke: "grey",
                  backgroundColor: "red",
                }}
              />
            }
            data={talkTimes}
          />
          <VictoryAxis
            label={t("talktime:conversationTime")}
            style={{
              axisLabel: { padding: 30, fontSize: 15 },
            }}
          />
          <VictoryAxis
            dependentAxis
            label={t("talktime:amountConversations")}
            style={{
              axisLabel: { padding: 50, fontSize: 15 },
            }}
          />
        </VictoryChart>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            marginTop: "-20px",
          }}>
          {t("talktime:totalConversations")}: {total}
        </Typography>
      </Paper>
    </Container>
  );
};

export default TalkTime;
