import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  changeNameChannels,
  channelsList,
} from "../../shared/helper/analyticsHelper.js";

const ChannelSelect = ({ setChannels }) => {
  const { t } = useTranslation();

  const [isDeselectAll, setIsDeselectAll] = useState(false);
  const [channelsSelected, setChannelsSelected] = useState([
    "MESSENGER",
    "WHATSAPP",
    "INSTAGRAMDIRECT",
    "WEBCHAT",
    "WEB",
    "msteams",
  ]);
  const [allChannelLabel, setAllChannelLabel] = useState(
    t("channelSelect:all"),
  );

  useEffect(() => {
    setChannels(channelsSelected);
  }, [channelsSelected]);

  const handleChannels = (channels) => {
    if (channels.includes(t("channelSelect:all"))) {
      if (channelsSelected.length < 6) {
        setChannelsSelected([
          "MESSENGER",
          "WHATSAPP",
          "INSTAGRAMDIRECT",
          "WEBCHAT",
          "WEB",
          "msteams",
        ]);
        setIsDeselectAll(false);
      } else {
        setChannelsSelected([]);
        setAllChannelLabel(t("channelSelect:all"));
        setIsDeselectAll(true);
      }
    } else {
      setAllChannelLabel(t("channelSelect:all"));
      setChannelsSelected(channels);
      setIsDeselectAll(channels.length === 0);
    }
  };

  return (
    <FormControl
      sx={{
        minWidth: 130,
        maxWidth: 150,
        margin: "0px 5px 0px 5px",
        position: "relative",
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: isDeselectAll ? "red" : "2C2F88",
          },
        },
      }}>
      <InputLabel
        sx={{
          "&.MuiInputLabel-shrink": {
            color: isDeselectAll ? "red" : "inherit",
          },
        }}>
        {t("channelSelect:channel")}
      </InputLabel>
      <Select
        id="intents-ranking-channels"
        label={t("channelSelect:channel")}
        onChange={(e) => {
          handleChannels(e.target.value);
        }}
        multiple
        value={channelsSelected}
        defaultValue={[
          "MESSENGER",
          "WHATSAPP",
          "INSTAGRAMDIRECT",
          "msteams",
          "WEBCHAT",
          "WEB",
          "msteams",
        ]}
        renderValue={(selected) => {
          if (selected.length === 6) {
            setAllChannelLabel(t("channelSelect:deselectAll"));
            return t("channelSelect:all");
          } else {
            return changeNameChannels(selected).join(", ");
          }
        }}>
        <MenuItem value={t("channelSelect:all")} sx={{ fontStyle: "italic" }}>
          <Checkbox
            checked={channelsSelected.length === 6}
            indeterminate={
              channelsSelected.length > 0 && channelsSelected.length < 6
            }
          />
          {allChannelLabel}
        </MenuItem>
        {channelsList.map((channel) => (
          <MenuItem value={channel.value} key={channel.value}>
            <Checkbox checked={channelsSelected.indexOf(channel.value) > -1} />
            {channel.label}
          </MenuItem>
        ))}
      </Select>
      {isDeselectAll && (
        <Typography
          sx={{
            color: "red",
            fontSize: "12px",
            marginTop: "4px",
            minHeight: "16px",
            width: "100%",
            bottom: "-18px",
            position: "absolute",
            whiteSpace: "nowrap",
          }}>
          {t("channelSelect:thisFieldIsRequired")}
        </Typography>
      )}
    </FormControl>
  );
};

ChannelSelect.propTypes = {
  setChannels: PropTypes.func.isRequired,
};

export default React.memo(ChannelSelect);
