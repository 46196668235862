import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import {
  validateCharacters,
  validateEmpty,
} from "../../../../shared/helper/validations";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";

export default function EdgeEntity({ ...props }) {
  const [entities, setEntities] = React.useState([]);
  const [dirtyFields, setDirtyFields] = React.useState({
    name: false,
    value: false,
  });
  const [errors, setErrors] = React.useState({
    name: "",
    value: "",
  });
  const { t } = useTranslation("edge");

  useEffect(() => {
    if (props.entity) {
      setEntities([props.entity]);
      if (!isEqual(props.entity, {})) {
        setDirtyFields({ name: true, value: true });
        validateFields(props.entity);
      }
    } else {
      setEntities([{}]);
    }
  }, []);

  const validateFields = (entity) => {
    const newErrors = {
      name: "",
      value: "",
    };

    // Name validations
    if (!validateEmpty(entity.name)) {
      newErrors.name = t("validateNameEmpty");
    } else if (!validateCharacters(entity.name)) {
      newErrors.name = t("validateNameInvalid");
    }

    // Value validations
    if (!validateEmpty(entity.value)) {
      newErrors.value = t("validateRegex");
    }

    setErrors(newErrors);
    return [Object.values(newErrors).every((error) => error === ""), newErrors];
  };

  async function updateName(e, index) {
    let current = [...entities];
    current[index].name = e.target.value;
    setEntities(current);
    const newDirtyFields = { ...dirtyFields, name: true };
    setDirtyFields(newDirtyFields);
    const [valid, errors] = validateFields(current[0]);
    props.entityUpdate(
      current[0],
      valid,
      areDirtyFieldsValid(newDirtyFields, errors),
    );
  }

  async function updateValue(e, index) {
    let current = [...entities];
    current[index].value = e.target.value;
    setEntities(current);
    const newDirtyFields = { ...dirtyFields, value: true };
    setDirtyFields(newDirtyFields);
    const [valid, errors] = validateFields(current[0]);
    props.entityUpdate(
      current[0],
      valid,
      areDirtyFieldsValid(newDirtyFields, errors),
    );
  }

  function updateNotFoundMessage(e, index) {
    let current = [...entities];
    current[index].notFoundMessage = e.target.value;
    setEntities(current);
    const valid = validateFields(current[0]);
    props.entityUpdate(current[0], valid);
  }

  async function updateNotFoundTransition(e, index) {
    let current = [...entities];
    current[index].notFoundTransition =
      e.target.value !== "" ? e.target.value : null;
    setEntities(current);
    const valid = validateFields(current[0]);
    props.entityUpdate(current[0], valid);
  }

  const areDirtyFieldsValid = (dirtyFields, errors) => {
    return Object.keys(dirtyFields).some((key) => {
      const isDirty = dirtyFields[key];
      const hasError = errors[key] !== "";
      return isDirty && hasError;
    });
  };

  return (
    <div>
      <h3 className="entity-title">{t("entities")}</h3>

      {entities.map((item, index) => (
        <div key={index} className="entity">
          <Tooltip
            title={props.userPermissions ? "" : t("noPermissionTooltip")}
            placement="left">
            <TextField
              id="entity-name"
              className="entity-name"
              label={t("entitieName")}
              variant="standard"
              multiline
              required
              disabled={!props.userPermissions}
              fullWidth
              defaultValue={item.name}
              onChange={(e) => updateName(e, index)}
              error={dirtyFields.name && errors.name !== ""}
              helperText={dirtyFields.name ? errors.name : ""}
            />
          </Tooltip>
          <Tooltip
            title={props.userPermissions ? "" : t("noPermissionTooltip")}
            placement="left">
            <TextField
              id="entity-regex"
              className="entity-regex"
              label={t("entitieRegex")}
              variant="standard"
              required
              disabled={!props.userPermissions}
              multiline
              fullWidth
              defaultValue={item.value}
              onChange={(e) => updateValue(e, index)}
              error={dirtyFields.value && errors.value !== ""}
              helperText={dirtyFields.value ? errors.value : ""}
            />
          </Tooltip>
          <Tooltip
            title={props.userPermissions ? "" : t("noPermissionTooltip")}
            placement="left">
            <TextField
              id="entity-not-found-message"
              className="transition-message"
              label={t("entitieNotFoundMessage")}
              variant="standard"
              multiline
              disabled={!props.userPermissions}
              fullWidth
              defaultValue={item.notFoundMessage}
              onChange={(e) => updateNotFoundMessage(e, index)}
            />
          </Tooltip>
          <FormControl fullWidth>
            <InputLabel>{t("entitieNotFoundPage")}</InputLabel>
            <Tooltip
              title={props.userPermissions ? "" : t("noPermissionTooltip")}
              placement="left">
              <Select
                id="entity-not-found-transition"
                variant="standard"
                disabled={!props.userPermissions}
                defaultValue={
                  item.notFoundTransition
                    ? item.notFoundTransition._id
                      ? item.notFoundTransition._id
                      : item.notFoundTransition
                    : ""
                }
                onChange={(e) => updateNotFoundTransition(e, index)}>
                <MenuItem value="">
                  <i>None</i>
                </MenuItem>
                {props.pages.map((page) => (
                  <MenuItem key={page} value={page.data.oid}>
                    {page.data.label}
                  </MenuItem>
                ))}
              </Select>
            </Tooltip>
          </FormControl>
        </div>
      ))}
    </div>
  );
}

EdgeEntity.propTypes = {
  entity: PropTypes.object,
  entityUpdate: PropTypes.func.isRequired,
  userPermissions: PropTypes.bool.isRequired,
  pages: PropTypes.array.isRequired,
};
