import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Typography,
  Tooltip,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
  FormHelperText,
} from "@mui/material";

import DateRange from "../../shared/components/DateRange";
import TimeRange from "../../shared/components/TimeRange";
import dayjs from "dayjs";
import { DayPicker } from "../../shared/components/DayPicker";
import { HolidayPicker } from "../../shared/components/HolidayPicker";
import QuickReplies from "../../Flowgraph/Components/NodeComponents/Quick.replies";
import Delete from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";

const NewStepThree = ({
  intent,
  onClose,
  setNewIntent,
  intents,
  onSave,
  userPermissions,
}) => {
  const { t } = useTranslation("intents");
  const [quickReplies, setQuickReplies] = useState([]);
  const [quickRepliesDinamyc, setQuickRepliesDinamyc] = useState([]);
  const [quickRepliesName, setQuickRepliesName] = useState("");
  const [responseAnimated, setResponseAnimated] = useState("");
  const [responseImageType, setResponseImageType] = useState("");
  const [responseImagePosition, setResponseImagePosition] = useState(false);

  const [conditions, setConditions] = useState([]);
  const [errors, setErrors] = useState({});
  const [errorsQuickReplies, setErrorsQuickReplies] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [dateOperations, setDateOperations] = useState([
    {
      name: t("intents:time"),
      value: "time",
    },
    {
      name: t("intents:date"),
      value: "date",
    },
    {
      name: t("intents:dayName"),
      value: "day",
    },
    {
      name: t("intents:holiday"),
      value: "holiday",
    },
  ]);

  const handleAddCondition = () => {
    const id = conditions.length ? conditions.length + 1 : 1;
    const newCondition = {
      id: id,
      op: "time",
      value: {
        startTime: dayjs().startOf("day").hour(0).format("HH:mm:ss"),
        endTime: dayjs().startOf("day").hour(23).format("HH:mm:ss"),
      },
    };
    setConditions((prevConditions) => [...prevConditions, newCondition]);
  };

  const handleSave = useCallback(() => {
    let newIntent = intent;
    if (quickReplies?.length > 0) {
      newIntent.quickReplies = quickReplies;
    }
    if (quickRepliesDinamyc?.length > 0) {
      newIntent.quickRepliesDinamyc = quickRepliesDinamyc;
    }
    if (quickRepliesName?.length > 0) {
      newIntent.quickRepliesName = quickRepliesName;
    }
    if (conditions?.length > 0) {
      newIntent.conditions = conditions;
    }
    if (responseAnimated?.length > 0) {
      newIntent.response.responseAnimated = responseAnimated;
      newIntent.response.responseImageType = responseImageType;
      newIntent.response.responseImagePosition = responseImagePosition;
    }

    setNewIntent(newIntent);
    onSave();
  }, [
    intent,
    quickReplies,
    quickRepliesDinamyc,
    quickRepliesName,
    conditions,
    responseAnimated,
    setNewIntent,
    onSave,
    responseImageType,
    responseImagePosition,
  ]);

  const updateDateOp = (e, index) => {
    let edit = conditions[index];
    if (edit.length === 0) {
      edit.push({});
    }
    edit.op = e.target.value;
    if (e.target.value === "time") {
      edit.value = {
        startTime: dayjs().startOf("day").hour(0).format("HH:mm:ss"),
        endTime: dayjs().startOf("day").hour(23).format("HH:mm:ss"),
      };
    } else if (e.target.value === "date") {
      edit.value = {
        startDate: new Date(),
        endDate: new Date(),
      };
    } else if (e.target.value === "holiday") {
      edit.value = [];
    } else {
      delete edit.value;
    }
    //set conditions with new edit
    setConditions((prevConditions) => [
      ...prevConditions.slice(0, index),
      edit,
      ...prevConditions.slice(index + 1),
    ]);
  };

  const isAddDisabled = useMemo(() => {
    const isTextTooLong = intent.response.text?.some((text) => text.length > 1024);
    const isAltTooLong = intent.response.responseAlt?.some((alt) => alt.length > 1024);
    return isTextTooLong || isAltTooLong;
  }, [intent.response.text, intent.response.responseAlt]);

  const hasErrors = useMemo(
    () => Object.keys(errors).some((x) => errors[x] !== ""),
    [errors],
  );

  const hasErrorsQuickReplies = useMemo(
    () =>
      Object.keys(errorsQuickReplies).some((x) => errorsQuickReplies[x] !== ""),
    [errorsQuickReplies],
  );

  const updateQuickReplies = (edit) => {
    setQuickReplies(edit.static);
    setQuickRepliesDinamyc(edit.quickRepliesDinamyc);
    setQuickRepliesName(edit.quickRepliesName);

    let tempErrorsQuickReplies = {};
    if (edit.static?.length > 0) {
      for (let i = 0; i < edit.static.length; i++) {
        if (edit.static[i].value === "" || edit.static[i].label === "") {
          tempErrorsQuickReplies[i] = true;
        }
      }
    }
    if (edit.quickRepliesDynamic?.length > 0) {
      if (
        edit.quickRepliesSwitch === true &&
        edit.quickRepliesDynamic.length === 1 &&
        Object.keys(edit.quickRepliesDynamic[0]).length !== 3
      ) {
        tempErrorsQuickReplies[0] = true;
      }
      for (let i = 0; i < edit.quickRepliesDynamic.length; i++) {
        if (
          edit.quickRepliesDynamic[i].arrayName === "" ||
          edit.quickRepliesDynamic[i].label === "" ||
          edit.quickRepliesDynamic[i].value === ""
        ) {
          tempErrorsQuickReplies[i] = true;
        }
      }
    }
    setErrorsQuickReplies(tempErrorsQuickReplies);
  };

  useEffect(() => {
    let tempErrors = {};
    let tempConditionsErrors = {};
    if (responseAnimated && !responseImageType) {
      tempErrors.responseImageType = true;
    }
    if (conditions.length > 0) {
      conditions.forEach((condition, index) => {
        tempConditionsErrors[index] = tempConditionsErrors[index] || {}; // Asegúrate de que tempConditionsErrors[index] es un objeto
        if (!condition.reply || condition.reply === "") {
          tempConditionsErrors[index].reply = true;
        }
        if (
          condition.op === "day" &&
          (!condition.value || condition.value.length === 0 || condition.value?.every((item) => item === ""))
        ) {
          tempConditionsErrors[index].day = true;
        }
        if (
          condition.op === "date" &&
          (!condition.value.startDate || !condition.value.endDate)
        ) {
          tempConditionsErrors[index].date = true;
        }
        if (
          condition.op === "holiday" &&
          (!condition.value || condition.value.length === 0)
        ) {
          tempConditionsErrors[index].holiday = true;
        }
      });
    }
    if (
      Object.keys(tempConditionsErrors).length > 0 && //tempConditionsErrors no está vacío
      Object.values(tempConditionsErrors).some((x) =>
        Object.keys(x).some((y) => x[y] === true),
      )
    ) {
      //Al menos un valor de tempConditionsErrors tiene un valor true
      tempErrors.conditions = tempConditionsErrors;
    }
    setErrors(tempErrors);
  }, [conditions, responseAnimated, responseImageType]);

  const conditionsRender = useCallback(
    (condition, index) => {
      return (
        <div>
          {condition.op === "date" ? (
            <DateRange
              startDate={condition.value.startDate}
              endDate={condition.value.endDate}
              allowFuture={true}
              onDateSelected={(e) => {
                let newConditions = [...conditions];
                newConditions[index].value = e;
                setConditions(newConditions);
              }}
            />
          ) : null}
          {condition.op === "day" ? (
            <DayPicker
              days={condition.value ? condition.value : []}
              daySelected={(e) => {
                let newConditions = [...conditions];
                newConditions[index].value = e;
                setConditions(newConditions);
              }}
            />
          ) : null}
          {condition.op === "holiday" ? (
            <HolidayPicker
              selected={condition.value ? condition.value : []}
              holidaySelected={(e) => {
                let newConditions = [...conditions];
                newConditions[index].value = e;
                setConditions(newConditions);
              }}
              reverse={condition.reverse}
              onChecked={(e) => {
                let newConditions = [...conditions];
                newConditions[index].reverse = e;
                setConditions(newConditions);
              }}
            />
          ) : null}
          {condition.op === "time" ? (
            <TimeRange
              startTime={condition.value.startTime}
              endTime={condition.value.endTime}
              onTimeSelected={(e) => {
                let newConditions = [...conditions];
                newConditions[index].value = e;
                setConditions(newConditions);
              }}
            />
          ) : null}
        </div>
      );
    },
    [conditions],
  );

  return (
    <div>
      <Box p={2} sx={{ width: 600 }}>
        <Typography variant="h6" sx={{ marginLeft: "10%" }}>
          {"Media"}
        </Typography>
        <TextField
          label={t("intents:imageAdapter")}
          variant="standard"
          defaultValue={responseAnimated}
          sx={{
            marginTop: "15px",
            marginBottom: "25px",
            width: "80%",
            marginLeft: "10%",
          }}
          onChange={(e) => {
            setResponseAnimated(e.target.value);
          }}
        />
        <div>
          <Tooltip
            title={t("intents:tooltipGifs")}
            style={{
              verticalAlign: "middle",
              marginTop: "5%",
              marginRight: "2%",
              marginLeft: "10%",
            }}>
            <InfoIcon />
          </Tooltip>
          <FormControl
            style={{ width: "30%", marginLeft: "2%", marginTop: "5px" }}>
            <InputLabel id="response-image-type-label">
              {t("intents:imageType")}
            </InputLabel>
            <Select
              label={t("intents:imageType")}
              variant="standard"
              className="response-image-type"
              defaultValue={responseImageType}
              onChange={(e) => {
                setResponseImageType(e.target.value);
              }}
              fullWidth>
              <MenuItem value="">{t("intents:none")}</MenuItem>
              <MenuItem value="image/png">{t("intents:image")} PNG</MenuItem>
              <MenuItem value="image/jpeg">{t("intents:image")} JPEG</MenuItem>
              <MenuItem value="video/mp4">Video MP4</MenuItem>
              <MenuItem value="application/pdf">
                {t("intents:document")} PDF
              </MenuItem>
            </Select>
            {errors.responseImageType ? (
              <FormHelperText error={true}>
                {t("intents:errorImageType")}
              </FormHelperText>
            ) : null}
          </FormControl>
          <FormControlLabel
            label={t("intents:beforeMessage")}
            style={{ paddingLeft: "1%", paddingTop: "10px", marginLeft: "5%" }}
            control={
              <Switch
                onChange={(e) => {
                  setResponseImagePosition(e.target.checked);
                }}
                defaultChecked={responseImagePosition ? true : false}
              />
            }
          />
        </div>
      </Box>
      <Box p={2} sx={{ width: 600 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "80%",
            marginLeft: "10%",
          }}>
          <Typography variant="h6" sx={{ marginBottom: "10px" }}>
            {t("dateConditions")}
          </Typography>
          <div style={{ flexGrow: 1 }}></div>
          <Tooltip
            title={t("intents:tooltipCondition")}
            sx={{
              display: "flex",
              marginLeft: "auto",
              "&:hover": { color: "#4e8cff" },
            }}>
            <IconButton onClick={handleAddCondition}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
        {conditions.length > 0 ? (
          <div
            className="conditions"
            style={{ width: "80%", marginLeft: "10%" }}>
            {conditions.map((condition, index) => (
              <div key={index} className="condition">
                <div className="condition-buttons">
                  <IconButton
                    onClick={() => {
                      let newConditions = [...conditions];
                      newConditions.splice(index, 1);
                      setConditions(newConditions);
                    }}>
                    <Delete
                      sx={{
                        color: "deleteIcon.color",
                      }}
                    />
                  </IconButton>
                </div>
                <InputLabel shrink>{t("intents:operator")}</InputLabel>
                <Select
                  id="date-operator"
                  fullWidth
                  defaultValue={
                    condition.op ? condition.op : dateOperations[0].value
                  }
                  sx={{ marginBottom: "20px" }}
                  onChange={(e) => {
                    updateDateOp(e, index);
                  }}>
                  {dateOperations.map((item, index) => (
                    <MenuItem name={item.name} value={item.value} key={index}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {conditionsRender(condition, index)}
                <TextField
                  label={t("intents:conditionMessage")}
                  fullWidth
                  sx={{ marginTop: "1vh", marginBottom: "2vh" }}
                  variant="standard"
                  value={condition.reply}
                  error={
                    errors.conditions &&
                    errors.conditions[index] &&
                    errors.conditions[index].reply &&
                    errors.conditions[index].reply !== ""
                  }
                  helperText={
                    errors.conditions &&
                    errors.conditions[index] &&
                    errors.conditions[index].reply &&
                    errors.conditions[index].reply !== ""
                      ? t("intents:conditionMessageRequired")
                      : ""
                  }
                  onChange={(e) => {
                    let newConditions = [...conditions];
                    newConditions[index].reply = e.target.value;
                    setConditions(newConditions);
                  }}
                />
              </div>
            ))}
          </div>
        ) : null}
        {intent?.response?.text && intent.response.text.length > 0 ? (
          <div style={{ width: "80%", marginLeft: "10%" }}>
            <Typography
              variant="h6"
              sx={{ marginBottom: "10px", marginTop: "10px" }}>
              {t("quickReplies")}
            </Typography>
            <QuickReplies
              userPermissions={!userPermissions}
              quickReplies={{
                quickReplies: quickReplies,
                quickRepliesDinamyc: quickRepliesDinamyc,
                quickRepliesName: quickRepliesName,
              }}
              intents={intents}
              updateQuickReplies={updateQuickReplies}
              isAddDisabled={isAddDisabled}></QuickReplies>
          </div>
        ) : null}
      </Box>
      <Box
        p={2}
        sx={{ minWidth: 400, display: "flex", justifyContent: "flex-end" }}>
        <Button id="modal3-cancel-button" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button
          id="modal3-save-button"
          variant="contained"
          color="primary"
          disabled={hasErrorsQuickReplies || hasErrors}
          onClick={() => handleSave()}>
          {t("save")}
        </Button>
      </Box>
    </div>
  );
};

export default NewStepThree;
