import React, { useEffect, useState } from "react";
import { DialogContent, DialogTitle, TextField, Tooltip } from "@mui/material/";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import "../../../Flowgraph/Flowgraph.css";

import EdgeIntents from "./ModalComponents/edge.intents.component";
import EdgeConditions from "./ModalComponents/edge.conditions.component";
import EdgeEntity from "./ModalComponents/edge.entity.component";
import EdgeDateConditions from "./ModalComponents/edge.date.conditions.component";
import EdgeCleanParams from "./ModalComponents/edge.clean.params.component";
import EdgePresets from "./ModalComponents/edge.presets.component";
import EdgeMedia from "./ModalComponents/edge.media.component";

const EdgeModal = ({ ...props }) => {
  const [type, setType] = React.useState("intent");
  const [message, setMessage] = React.useState("");
  const [intents, setIntents] = React.useState([]);
  const [conditions, setConditions] = React.useState([]);
  const [entity, setEntity] = React.useState({});
  const [media, setMedia] = React.useState({});
  const [presets, setPresets] = React.useState([]);
  const [cleanParams, setCleanParams] = React.useState([]);
  const { t } = useTranslation();
  const [userPermissions, setUserPermissions] = useState(false);
  const [messageError, setMessageError] = useState({ message: "" });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props.updateModal) {
      setUserPermissions(props.userPermissions);
      setType(props.editTransition.type);
      setMessage(props.editTransition.message ?? "");
      setIntents(props.editTransition.intents ?? []);
      setConditions(cloneDeep(props.editTransition.conditions));
      setEntity(props.editTransition.entity);
      setMedia(props.editTransition.media);
      setPresets(props.editTransition.presets);
      setCleanParams(props.editTransition.cleanParams);
      props.setUpdateModal(false);
    }
  }, [props.updateModal]);

  const handleChangeType = (e) => {
    let previousType = type;
    let value = e.target.value;
    let valid = false;
    let tempEditTransition = cloneDeep(props.editTransition);

    if (value === "intent") {
      setIntents([props.intents[0].name]);
      valid = true;
    } else if (value === "condition") {
      const tempConditions = [
        {
          op: "eq",
        },
      ];
      setConditions(tempConditions);

      // In condition case, we need to update the conditions in the transition,
      // since the conditions are not updated by the child component (its correct)
      tempEditTransition.conditions = tempConditions;
    } else if (value === "date") {
      valid = true;
      const tempConditions = [
        {
          op: "time",
          value: {
            startTime: dayjs().startOf("day").format("HH:mm:ss"),
            endTime: dayjs().endOf("day").format("HH:mm:ss"),
          },
        },
      ];
      setConditions(tempConditions);
      tempEditTransition.conditions = tempConditions;
    } else if (value === "direct") {
      valid = true;
    }
    setType(value);

    // Reset the state of the other fields
    setEntity({});
    setMedia({});

    // dont use function return to set errors since the state update
    updateErrorState("type", false);

    tempEditTransition.type = value;
    props.onTransitionUpdate(tempEditTransition, valid, previousType, false);
  };

  const updateMessage = (e) => {
    const newMessage = e.target.value;
    const newError =
      newMessage.length > 1024 ? t("flowgraph:messageLenghtError") : "";

    // Update the error message if it has changed
    if (messageError.message !== newError) {
      setMessageError({ ...messageError, message: newError });
    }

    setMessage(newMessage);
  };

  const updateIntents = (update, valid) => {
    setIntents(update);

    const hasError = updateErrorState("intents", valid);

    let tempEditTransition = cloneDeep(props.editTransition);
    tempEditTransition.intents = update;
    props.onTransitionUpdate(tempEditTransition, !hasError);
  };

  const updateConditions = (update, valid, dirty = true) => {
    setConditions((prev) => {
      if (JSON.stringify(prev) === JSON.stringify(update)) {
        return prev; // Return same reference if contents haven't changed
      }
      return update;
    });

    const hasError = updateErrorState("conditions", valid);

    let tempEditTransition = cloneDeep(props.editTransition);
    tempEditTransition.conditions = update;
    props.onTransitionUpdate(tempEditTransition, !hasError, null, dirty);
  };

  const updateEntity = (update, valid, dirty = false) => {
    setEntity(update);

    const hasError = updateErrorState("entity", valid);

    let tempEditTransition = cloneDeep(props.editTransition);
    tempEditTransition.entity = update;
    props.onTransitionUpdate(tempEditTransition, !hasError, null, dirty);
  };

  const updateMedia = (update, valid) => {
    setMedia(update);

    const hasError = updateErrorState("media", valid);

    let tempEditTransition = cloneDeep(props.editTransition);
    tempEditTransition.media = update;
    props.onTransitionUpdate(tempEditTransition, !hasError);
  };

  const updatePresets = (update, valid) => {
    setPresets(update);

    const hasError = updateErrorState("presets", valid);

    let tempEditTransition = cloneDeep(props.editTransition);
    tempEditTransition.presets = update;
    props.onTransitionUpdate(tempEditTransition, !hasError);
  };

  const updateCleanParams = (update) => {
    setCleanParams(update);

    let tempEditTransition = cloneDeep(props.editTransition);
    tempEditTransition.cleanParams = update;
    props.onTransitionUpdate(tempEditTransition, true);
  };

  const updateErrorState = (key, valid) => {
    const newErrors = { ...errors };
    if (valid) {
      delete newErrors[key];
    } else if (key === "type") {
      Object.keys(newErrors).forEach((k) => {
        if (k !== "cleanParams" && k !== "presets") {
          delete newErrors[k];
        }
      });
    } else {
      newErrors[key] = true;
    }
    setErrors(newErrors);
    return Object.values(newErrors).some((e) => e);
  };
  return (
    <div hidden={props.selectedTab !== props.index}>
      {props.selectedTab === props.index && (
        <div>
          <DialogTitle>
            <Typography
              sx={{
                display: "inline-flex",
                float: "right",
                fontSize: "0.7rem",
              }}>
              {props.editEdge?.source?.data?.label}
              <ChevronRightIcon sx={{ fontSize: "0.9rem" }} />
              {props.editEdge?.target?.data?.label}
            </Typography>
            <Typography style={{ display: "inline-flex" }}>
              {t("flowgraph:transitionEdit")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <RadioGroup
              aria-label="entityType"
              name="entityType"
              value={type ?? "intent"}
              onChange={handleChangeType}>
              <Tooltip
                title={
                  userPermissions ? "" : t("flowgraph:noPermissionTooltip")
                }
                placement="left">
                <FormControlLabel
                  value="intent"
                  disabled={!userPermissions}
                  control={<Radio />}
                  label={t("flowgraph:intent")}
                />
              </Tooltip>
              <Tooltip
                title={
                  userPermissions ? "" : t("flowgraph:noPermissionTooltip")
                }
                placement="left">
                <FormControlLabel
                  value="condition"
                  disabled={!userPermissions}
                  control={<Radio />}
                  label={t("flowgraph:condition")}
                />
              </Tooltip>
              <Tooltip
                title={
                  userPermissions ? "" : t("flowgraph:noPermissionTooltip")
                }
                placement="left">
                <FormControlLabel
                  value="entity"
                  disabled={!userPermissions}
                  control={<Radio />}
                  label={t("flowgraph:entity")}
                />
              </Tooltip>
              <Tooltip
                title={
                  userPermissions ? "" : t("flowgraph:noPermissionTooltip")
                }
                placement="left">
                <FormControlLabel
                  value="date"
                  disabled={!userPermissions}
                  control={<Radio />}
                  label={t("flowgraph:date")}
                />
              </Tooltip>
              <Tooltip
                title={
                  userPermissions ? "" : t("flowgraph:noPermissionTooltip")
                }
                placement="left">
                <FormControlLabel
                  value="direct"
                  disabled={!userPermissions}
                  control={<Radio />}
                  label={t("flowgraph:direct")}
                />
              </Tooltip>
              <Tooltip
                title={
                  userPermissions ? "" : t("flowgraph:noPermissionTooltip")
                }
                placement="left">
                <FormControlLabel
                  value="media"
                  disabled={!userPermissions}
                  control={<Radio />}
                  label={t("flowgraph:media")}
                />
              </Tooltip>
            </RadioGroup>
            <Tooltip
              title={userPermissions ? "" : t("flowgraph:noPermissionTooltip")}
              placement="left">
              <TextField
                id="transition-message"
                label={t("flowgraph:message")}
                variant="standard"
                className="transition-message"
                multiline
                disabled={!userPermissions}
                fullWidth
                defaultValue={message}
                onChange={updateMessage}
                error={messageError.message}
                helperText={messageError.message}
              />
            </Tooltip>
            {type === "intent" ? (
              <EdgeIntents
                userPermissions={userPermissions}
                list={props.intents}
                intents={intents}
                intentsUpdate={updateIntents}
              />
            ) : null}
            {type === "condition" ? (
              <EdgeConditions
                userPermissions={userPermissions}
                conditions={conditions}
                conditionsUpdate={updateConditions}
              />
            ) : null}
            {type === "entity" ? (
              <EdgeEntity
                userPermissions={userPermissions}
                entity={entity}
                entityUpdate={updateEntity}
                pages={props.pages}
              />
            ) : null}
            {type === "date" ? (
              <EdgeDateConditions
                userPermissions={userPermissions}
                dateConditions={conditions}
                dateConditionsUpdate={updateConditions}
                edges={props.edges}
                source={props.editEdge?.data?.sourceId}
              />
            ) : null}
            {type === "media" ? (
              <EdgeMedia
                userPermissions={userPermissions}
                media={media}
                mediaUpdate={updateMedia}
              />
            ) : null}
            {!props.conflictTransitions[props.selectedTab] ? (
              <Typography
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "3%",
                }}>
                {t("flowgraph:errorSameTransition")}
              </Typography>
            ) : null}
            <EdgeCleanParams
              userPermissions={userPermissions}
              cleanParams={cleanParams}
              cleanParamsUpdate={updateCleanParams}
            />
            <EdgePresets
              userPermissions={userPermissions}
              presets={presets}
              presetsUpdate={updatePresets}
            />
          </DialogContent>
        </div>
      )}
    </div>
  );
};

EdgeModal.propTypes = {
  userPermissions: PropTypes.bool.isRequired,
  selectedTab: PropTypes.number,
  index: PropTypes.number,
  edges: PropTypes.array.isRequired,
  intents: PropTypes.array.isRequired,
  pages: PropTypes.array.isRequired,
  editTransition: PropTypes.object.isRequired,
  editEdge: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onTransitionUpdate: PropTypes.func.isRequired,
  setUpdateModal: PropTypes.func.isRequired,
  updateModal: PropTypes.bool.isRequired,
  conflictTransitions: PropTypes.array.isRequired,
};

export default EdgeModal;
