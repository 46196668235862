import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const compareValues = (key, value1, value2) => {
  if (typeof value1 === "object" && typeof value2 === "object") {
    return JSON.stringify(value1) !== JSON.stringify(value2);
  }
  return value1 !== value2;
};

const AiChanges = ({ ai = {}, originalAi = {} }) => {
  const { t } = useTranslation();
  const aiKeys = [
    "active",
    "context",
    "instruction",
    "abusiveLanguage",
    "abusiveTransferPage",
    "hasTemperature",
    "temperature",
    "hasTokens",
    "tokens",
    "hasSuggestion",
    "suggestionText",
    "hasPdfUrl",
    "pdfUrl",
  ];

  return aiKeys.map((key) => (
    <div key={key} style={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
        {t(key)}:
      </Typography>
      {compareValues(key, ai[key], originalAi[key]) ? (
        <>
          <Typography
            sx={{
              marginLeft: 2,
              fontSize: "14px",
              color: "tracesName.delete",
              textDecoration: "line-through",
            }}>
            {originalAi[key] !== undefined ? originalAi[key].toString() : "N/A"}
          </Typography>
          <Typography
            sx={{
              marginLeft: 2,
              fontSize: "14px",
              color: "tracesName.new",
            }}>
            {ai[key].toString()}
          </Typography>
        </>
      ) : (
        <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
          {ai[key].toString()}
        </Typography>
      )}
    </div>
  ));
};

export default AiChanges;
