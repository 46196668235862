import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const compareValues = (key, value1, value2) => {
  if (typeof value1 === "object" && typeof value2 === "object") {
    return JSON.stringify(value1) !== JSON.stringify(value2);
  }
  return value1 !== value2;
};

const PositionChanges = ({ position = {}, originalPosition = {} }) => {
  const { t } = useTranslation();
  const keys = ["x", "y"];

  return keys.map((key) => (
    <div key={key} style={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
        {t(key)}:
      </Typography>
      {compareValues(key, position[key], originalPosition[key]) ? (
        <>
          <Typography
            sx={{
              marginLeft: 2,
              fontSize: "14px",
              color: "tracesName.delete",
              textDecoration: "line-through",
            }}>
            {originalPosition[key] !== undefined
              ? originalPosition[key].toString()
              : "N/A"}
          </Typography>
          <Typography
            sx={{
              marginLeft: 2,
              fontSize: "14px",
              color: "tracesName.new",
            }}>
            {position[key].toString()}
          </Typography>
        </>
      ) : (
        <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
          {position[key].toString()}
        </Typography>
      )}
    </div>
  ));
};

export default PositionChanges;
