import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";

import InfoIcon from "@mui/icons-material/Info";
import InputLabel from "@mui/material/InputLabel";

import { Autocomplete } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { isEmpty } from "lodash";

export default function EdgeIntents({ ...props }) {
  const [list, setList] = React.useState([]);
  const [valid, setValid] = React.useState(true);
  const [intents, setIntents] = React.useState([]);
  const { t } = useTranslation("transitionIntent");

  const prevIntentsRef = useRef();

  useEffect(() => {
    setIntents(props.intents);
    orderIntents(props.list);
    validate(props.intents);
  }, []);

  useEffect(() => {
    if (props.intents.length !== 0) {
      setIntents(props.intents);
      orderIntents(props.list);
      validate(props.intents);
    }
  }, [props.intents, valid]);

  useEffect(() => {
    prevIntentsRef.current = props.intents;
  }, [intents]);

  async function handleChangeIntent(intent) {
    const intentValues = (intent || [])
      .filter((item) => item) // Filtrar nulls/undefined
      .map((item) => (typeof item === "object" ? item.value : item));

    setIntents(intentValues);
    let valid = validate(intentValues);
    props.intentsUpdate(intentValues, valid);
  }

  function validate(intent) {
    let valid = true;
    if (intent?.length === 0) valid = false;
    setValid(valid);
    return valid;
  }

  async function orderIntents(unsortedIntents) {
    let sortedIntents = unsortedIntents.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
    );
    let options = await clearIntentsOptions(sortedIntents);
    setList(options);
  }

  async function clearIntentsOptions(sortedIntents) {
    let intentsOptions = [];
    sortedIntents
      .filter((intent) => isEmpty(intent.destinationPage))
      .forEach((intent) =>
        intentsOptions.push({
          label: intent.name.replace(/-/g, " "),
          value: intent.name,
        }),
      );
    return intentsOptions;
  }

  return (
    <div className="intent-select">
      <div className="intent-label">
        <InputLabel id="flow-edge-intents-inputLabel">
          {t("intents")}
        </InputLabel>
        <Tooltip title={t("intentsHelper")} sx={{ marginLeft: "2px" }}>
          <InfoIcon />
        </Tooltip>
      </div>
      <Tooltip title={!props.userPermissions ? t("noPermissionsTooltip") : ""}>
        <Autocomplete
          id="flow-edge-intents-autocomplete"
          sx={{ marginTop: "10px" }}
          autoHighlight
          loading
          disabled={!props.userPermissions}
          multiple
          disableCloseOnSelect
          value={intents ? intents : []}
          disabledItemsFocusable
          onChange={(e, intent) => {
            handleChangeIntent(intent);
          }}
          options={list}
          getOptionLabel={(option) => {
            if (!option) return "";
            return typeof option === "object"
              ? option.label || ""
              : option.replace
              ? option.replace(/-/g, " ")
              : option;
          }}
          isOptionEqualToValue={(option, value) => {
            if (!option || !value) return false;
            const optionValue =
              typeof option === "object" ? option.value : option;
            const compareValue =
              typeof value === "object" ? value.value : value;
            return optionValue === compareValue;
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          renderInput={(intent) => (
            <Tooltip
              title={!props.userPermissions ? t("noPermissionTooltip") : ""}>
              <TextField
                {...intent}
                disabled={!props.userPermissions}
                label={t("intents")}
                error={!valid}
                helperText={valid ? "" : t("errorIntents")}
              />
            </Tooltip>
          )}
        />
      </Tooltip>
    </div>
  );
}
