import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
  IconButton,
  Box,
  Grid,
  TextField,
  Tooltip,
  FormControlLabel,
  Switch,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import DateRange from "../../shared/components/DateRange";
import TimeRange from "../../shared/components/TimeRange";
import dayjs from "dayjs";
import { DayPicker } from "../../shared/components/DayPicker";
import { HolidayPicker } from "../../shared/components/HolidayPicker";
import QuickReplies from "../../Flowgraph/Components/NodeComponents/Quick.replies";
import Moment from "moment";
import TextDnDComponent from "../../shared/components/TextDnD/TextDnD.component";



const Properties = ({
  row,
  tags,
  onSave,
  intents,
  agentPages,
  userPermissions,
  noTagsPermissions,
}) => {
  
  const { t } = useTranslation("intents");
  const initialIntent = JSON.parse(JSON.stringify(row));
  if (
    initialIntent.tags &&
    Array.isArray(initialIntent.tags) &&
    initialIntent.tags[0] &&
    typeof initialIntent.tags[0] === "object"
  ) {
    initialIntent.tags = initialIntent.tags.map((tag) => tag._id);
  }
  const [intent, setIntent] = useState(initialIntent);
  const [errors, setErrors] = useState({});
  const [validQuickR, setValidQuickR] = useState(false);
  const [errorsConditions, setErrorsConditions] = useState({});
  const [showQuickReplies, setShowQuickReplies] = useState(false);
  const [responseAlt, setResponseAlt] = useState([]);
  const dateOperations = [
    {
      name: t("intents:time"),
      value: "time",
    },
    {
      name: t("intents:date"),
      value: "date",
    },
    {
      name: t("intents:dayName"),
      value: "day",
    },
    {
      name: t("intents:holiday"),
      value: "holiday",
    },
  ];

  const updateDateOp = async (e, index) => {
    let edit = intent;

    // Agregamos un valor al campo si está vacío
    if (edit.conditions.length === 0) {
      edit.conditions.push({});
    }
    edit.conditions[index].op = e.target.value;
    if (e.target.value === "time") {
      edit.conditions[index].value = {
        startTime: dayjs().startOf("day").hour(0).format("HH:mm:ss"),
        endTime: dayjs().startOf("day").hour(23).format("HH:mm:ss"),
      };
    } else if (e.target.value === "date") {
      edit.conditions[index].value = {
        startDate: dayjs(),
        endDate: dayjs(),
      };
    } else if (e.target.value === "holiday") {
      edit.conditions[index].value = [];
    } else if (e.target.value === "day") {
      edit.conditions[index].value = [];
    } else {
      delete edit.value;
    }
    setIntent({ ...intent, conditions: edit.conditions });
  };

  const handleAddCondition = () => {
    const id =
      intent.conditions && intent.conditions.length > 0
        ? intent.conditions.length + 1
        : 1;
    const newIntent = { ...intent };
    if (!newIntent.conditions) newIntent.conditions = [];
    newIntent.conditions.push({
      id: id,
      op: "time",
      value: {
        startTime: dayjs().startOf("day").hour(0).format("HH:mm:ss"),
        endTime: dayjs().startOf("day").hour(23).format("HH:mm:ss"),
      },
    });
    setIntent(newIntent);
  };

  const saveChanges = (intent) => {
    onSave(intent);

    if (intent.destinationPage === "") {
      delete intent.destinationPage;
    }
    if (intent.response.responseAnimated === "") {
      delete intent.response.responseAnimated;
    }
    if (intent.response.responseImageType === "") {
      delete intent.response.responseImageType;
    }
  };

  //validation useEffect
  useEffect(() => {
    //errors and errorsConditions are a diferent state because we need to validate the conditions
    const tempErrors = {};

    if (
      intent.response?.responseAnimated &&
      (intent.response?.responseImageType === "" ||
        intent.response?.responseImageType === undefined)
    ) {
      tempErrors.responseImageType = t("intents:errorImageType");
    }
    if (errors.date) {
      tempErrors.date = errors.date;
    }

    validateConditions();

    delete intent.phrases;
    delete intent.response.text;
    delete intent.name;

    setErrors({ ...tempErrors });
  }, [intent]);

  useEffect(() => {
    const temp = {};

    for (const alt of responseAlt) {
      if (alt.length > 10000) {
        temp.responseAlt = t("intents:errorLengthValidation");
        break;
      } else {
        temp.responseAlt = "";
      }
    }
    setErrors({ ...temp });
  }, [responseAlt, t]);

  const validateConditions = () => {
    const tempErrorsConditions = {};

    intent.conditions?.forEach((condition, index) => {
      let errorsTemp = {};

      switch (condition.op) {
        case "time":
          if (!condition.value?.startTime) {
            errorsTemp.startTime = t("intents:startTimeRequired");
          }

          if (!condition.value?.endTime) {
            errorsTemp.endTime = t("intents:startTimeRequired");
          }
          if (
            Moment(condition.value?.endTime, "HH:mm:ss a").isBefore(
              Moment(condition.value?.startTime, "HH:mm:ss a"),
            )
          )
            errorsTemp.invalidTime = t("intents:invalidTimeSelection");
          break;
        case "date":
          if (!condition.value?.startDate) {
            errorsTemp.startDate = t("intents:startTimeRequired");
          }

          if (!condition.value?.endDate) {
            errorsTemp.endDate = t("intents:startTimeRequired");
          }
          if (
            Moment(condition.value?.endDate, "YYYY-MM-DD").isBefore(
              Moment(condition.value?.startDate, "YYYY-MM-DD"),
            )
          )
            errorsTemp.invalidTime = t("intents:invalidTimeSelection");
          break;
        case "day":
          if (condition.value.startTime || condition.value.startDate) {
            errorsTemp.invalidDay = t("intents:invalidDaySelection");
            break;
          }

          if (condition.value?.every((item) => item === "")) {
            errorsTemp.invalidDay = t("intents:invalidDaySelection");
          }
          break;
        case "holiday":
          if (condition.value?.length === 0) {
            errorsTemp.invalidHoliday = t("intents:invalidHoliday");
          }
          break;
        default:
          break;
      }

      if (!condition.reply || condition.reply === "") {
        errorsTemp.reply = t("intents:conditionMessageRequired");
      }

      if (Object.keys(errorsTemp).length > 0) {
        tempErrorsConditions[index] = errorsTemp;
      }
    });
    setErrorsConditions({ ...tempErrorsConditions });
  };

  const hasErrors = useMemo(
    () => Object.keys(errors).some((x) => errors[x] !== ""),
    [errors],
  );

  const hasErrorsConditions = useMemo(
    () => Object.keys(errorsConditions).some((x) => errorsConditions[x] !== ""),
    [errorsConditions],
  );

  const conditionsRender = useCallback(
    (condition, index) => {
      return (
        <div>
          {condition.op === "date" ? (
            <Tooltip title={!userPermissions ? "" : t("noPermissionTooltip")}>
              <span>
                <DateRange
                  startDate={condition.value.startDate}
                  endDate={condition.value.endDate}
                  allowFuture={true}
                  disabled={userPermissions}
                  setError={setErrors}
                  errors={errors}
                  onDateSelected={(e) => {
                    setIntent((prevIntent) => {
                      const newIntent = { ...prevIntent };
                      newIntent.conditions[index].value = e;
                      return newIntent;
                    });
                  }}
                />
              </span>
            </Tooltip>
          ) : null}
          {condition.op === "day" ? (
            <Tooltip title={!userPermissions ? "" : t("noPermissionTooltip")}>
              <span>
                <DayPicker
                  days={condition.value}
                  disabled={userPermissions}
                  daySelected={(e) => {
                    setIntent((prevIntent) => {
                      const newIntent = { ...prevIntent };
                      newIntent.conditions[index].value = e;
                      return newIntent;
                    });
                  }}
                />
              </span>
            </Tooltip>
          ) : null}
          {condition.op === "holiday" ? (
            <Tooltip title={!userPermissions ? "" : t("noPermissionTooltip")}>
              <span>
                <HolidayPicker
                  selected={condition.value ? condition.value : []}
                  holidaySelected={(e) => {
                    setIntent((prevIntent) => {
                      const newIntent = { ...prevIntent };
                      newIntent.conditions[index].value = e;
                      return newIntent;
                    });
                  }}
                  reverse={condition.reverse}
                  disabled={userPermissions}
                  onChecked={(e) => {
                    setIntent((prevIntent) => {
                      const newIntent = { ...prevIntent };
                      newIntent.conditions[index].reverse = e;
                      return newIntent;
                    });
                  }}
                />
              </span>
            </Tooltip>
          ) : null}
          {condition.op === "time" ? (
            <Tooltip title={!userPermissions ? "" : t("noPermissionTooltip")}>
              <span>
                <TimeRange
                  startTime={condition.value.startTime}
                  endTime={condition.value.endTime}
                  disabled={userPermissions}
                  onTimeSelected={(e) => {
                    setIntent((prevIntent) => {
                      const newIntent = { ...prevIntent };
                      newIntent.conditions[index].value = e;
                      return newIntent;
                    });
                  }}
                />
              </span>
            </Tooltip>
          ) : null}
        </div>
      );
    },
    [intent],
  );

  const valuateQuickReplies = () => {
    // If response has no text, quick replies are not shown, and the result is saved in showQuickReplies if previous and actual values are different. If text does not exist, the previous showQuickReplies is returned
    if (intent.response.text === undefined) {
      return showQuickReplies;
    } else {
      if (intent.response.text.length > 0 !== showQuickReplies)
        setShowQuickReplies(intent.response.text.length > 0);
      return intent.response.text.length > 0;
    }
  };

  const handleDestinationPage = (e) => {
    const selectedpage = agentPages.find((page) => page.data.label === e);
    setIntent({
      ...intent,
      destinationPage: selectedpage ? selectedpage.id : "",
    });
  };

  const isAddDisabled = useMemo(() => {
    const isTextTooLong = intent.response.text?.some((text) => text.length > 1024);
    const isAltTooLong = intent.response.responseAlt?.some((alt) => alt.length > 1024);
    return isTextTooLong || isAltTooLong;
  }, [intent.response.text, intent.response.responseAlt]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "row", width: "70vw" }}>
        <Grid container columns={2}>
          <Grid item xs={1}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "30vw",
                borderRadius: "5px",
                padding: "10px",
              }}>
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                {t("intents:responses")}
              </Typography>
              <Autocomplete
                id="destination-page"
                label={t("intents:destinationPage")}
                fullWidth
                value={
                  intent.destinationPage
                    ? agentPages.find(
                      (page) => page.id === intent.destinationPage,
                    )?.data?.label
                    : ""
                }
                options={agentPages.map((page) => page.data.label)}
                onChange={(_, value) => handleDestinationPage(value)}
                defaultValue={intent.destinationPage}
                disabled={userPermissions}
                renderInput={(params) => (
                  <Tooltip
                    title={!userPermissions ? "" : t("noPermissionTooltip")}>
                    <span>
                      <Box display="flex" flexDirection="row">
                        <TextField
                          {...params}
                          label={t("destinationPage")}
                          variant="standard"
                          className="transition-message"
                          fullWidth></TextField>
                      </Box>
                    </span>
                  </Tooltip>
                )}></Autocomplete>
              <Tooltip title={!userPermissions ? "" : t("noPermissionTooltip")}>
                <span>
                  <TextDnDComponent
                    items={intent.response.responseAlt}
                    errors={!!errors.responseAlt}
                    setItems={(e) => {
                      setIntent((prevIntent) => {
                        const newIntent = { ...prevIntent };
                        newIntent.response.responseAlt = e;
                        return newIntent;
                      });
                      setResponseAlt(e);
                    }}
                    title={t("intents:alternativeMessage")}
                  />
                  <FormHelperText error>
                    {errors.responseAlt ? errors.responseAlt : ""}
                  </FormHelperText>
                </span>
              </Tooltip>
              <Tooltip title={!userPermissions ? "" : t("noPermissionTooltip")}>
                <span>
                  <TextField
                    id="suggestion-message"
                    label={t("intents:suggestionMessage")}
                    variant="standard"
                    fullWidth
                    disabled={userPermissions}
                    defaultValue={intent.suggestion_message}
                    sx={{ marginBottom: "25px" }}
                    onChange={(e) => {
                      setIntent((prevIntent) => {
                        const newIntent = { ...prevIntent };
                        newIntent.suggestion_message = e.target.value;
                        return newIntent;
                      });
                    }}
                    inputProps={{ maxLength: 20 }}
                  />
                </span>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "30vw",
                borderRadius: "5px",
                padding: "10px",
              }}>
              <Typography variant="h6">{"Media"}</Typography>
              <Tooltip title={!userPermissions ? "" : t("noPermissionTooltip")}>
                <span>
                  <TextField
                    id="image-adapter"
                    label={t("intents:imageAdapter")}
                    variant="standard"
                    fullWidth
                    disabled={userPermissions}
                    defaultValue={intent.response.responseAnimated}
                    sx={{ marginTop: "15px", marginBottom: "25px" }}
                    onChange={(e) => {
                      setIntent((prevIntent) => {
                        const newIntent = { ...prevIntent };
                        newIntent.response.responseAnimated = e.target.value;
                        return newIntent;
                      });
                    }}
                  />
                </span>
              </Tooltip>
              <div>
                <Tooltip
                  title={t("intents:tooltipGifs")}
                  style={{
                    verticalAlign: "middle",
                    marginTop: "7%",
                    marginRight: "2%",
                  }}>
                  <InfoIcon />
                </Tooltip>
                <FormControl
                  style={{ width: "85%", marginLeft: "2%", marginTop: "5px" }}>
                  <InputLabel id="response-image-type-label">
                    {t("intents:imageType")}
                  </InputLabel>
                  <Tooltip
                    title={!userPermissions ? "" : t("noPermissionTooltip")}>
                    <Select
                      id="image-type"
                      label={t("intents:imageType")}
                      variant="standard"
                      className="response-image-type"
                      disabled={userPermissions}
                      defaultValue={intent.response.responseImageType}
                      onChange={(e) => {
                        setIntent((prevIntent) => {
                          const newIntent = { ...prevIntent };
                          newIntent.response.responseImageType = e.target.value;
                          return newIntent;
                        });
                      }}
                      fullWidth>
                      <MenuItem id="image-type-none" value="">
                        {t("intents:none")}
                      </MenuItem>
                      <MenuItem id="image-type-png" value="image/png">
                        {t("intents:image")} PNG
                      </MenuItem>
                      <MenuItem id="image-type-jpeg" value="image/jpeg">
                        {t("intents:image")} JPEG
                      </MenuItem>
                      <MenuItem id="image-type-video-mp4" value="video/mp4">
                        Video MP4
                      </MenuItem>
                      <MenuItem id="image-type-pdf" value="application/pdf">
                        {t("intents:document")} PDF
                      </MenuItem>
                    </Select>
                  </Tooltip>
                  {errors.responseImageType ? (
                    <FormHelperText error={errors.responseImageType}>
                      {errors.responseImageType}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </div>
              <FormControlLabel
                label={t("intents:beforeMessage")}
                style={{ paddingLeft: "1%", paddingTop: "10px" }}
                control={
                  <Tooltip
                    title={!userPermissions ? "" : t("noPermissionTooltip")}>
                    <span>
                      <Switch
                        id="swtch-beforee-message"
                        onChange={(e) => {
                          setIntent((prevIntent) => {
                            const newIntent = { ...prevIntent };
                            newIntent.response.responseImagePosition =
                              e.target.checked;
                            return newIntent;
                          });
                        }}
                        disabled={userPermissions}
                        defaultChecked={
                          intent.response.responseImagePosition ? true : false
                        }
                      />
                    </span>
                  </Tooltip>
                }
              />
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "30vw",
                borderRadius: "5px",
                padding: "10px",
                marginTop: "2vh",
              }}>
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                {t("intents:various")}
              </Typography>
              <Grid container spacing={2} columns={3}>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">{t("oneWord")}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Tooltip
                    title={!userPermissions ? "" : t("noPermissionTooltip")}>
                    <span>
                      <Checkbox
                        id="oneWord-check"
                        checked={intent.oneWord}
                        disabled={userPermissions}
                        onClick={() => {
                          setIntent((prevIntent) => {
                            const newIntent = { ...prevIntent };
                            newIntent.oneWord = !newIntent.oneWord;
                            return newIntent;
                          });
                        }}></Checkbox>
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">
                    {t("excludeAlternatives")}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Tooltip
                    title={!userPermissions ? "" : t("noPermissionTooltip")}>
                    <span>
                      <Checkbox
                        id="excludeAlternatives-check"
                        checked={intent.excludeAlternatives}
                        disabled={userPermissions}
                        onClick={() => {
                          setIntent((prevIntent) => {
                            const newIntent = { ...prevIntent };
                            newIntent.excludeAlternatives =
                              !newIntent.excludeAlternatives;
                            return newIntent;
                          });
                        }}></Checkbox>
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle1">
                    {t("excludeIntents")}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Tooltip
                    title={!userPermissions ? "" : t("noPermissionTooltip")}>
                    <span>
                      <Checkbox
                        id="excludeIntents-check"
                        checked={intent.excludeRankings}
                        disabled={userPermissions}
                        onClick={() => {
                          setIntent((prevIntent) => {
                            const newIntent = { ...prevIntent };
                            newIntent.excludeRankings =
                              !newIntent.excludeRankings;
                            return newIntent;
                          });
                        }}></Checkbox>
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs={3}>
                  <Tooltip
                    title={noTagsPermissions ? t("noPermissionTooltip") : ""}>
                    <span>
                      <Autocomplete
                        multiple
                        disabled={noTagsPermissions}
                        id="intents_properties_tags"
                        options={tags}
                        getOptionLabel={(option) => option.name}
                        defaultValue={
                          intent.tags && intent.tags.length > 0
                            ? tags.filter((tag) =>
                              intent.tags.includes(tag._id),
                            )
                            : []
                        }
                        onChange={(e, value) => {
                          setIntent((prevIntent) => {
                            const newIntent = { ...prevIntent };
                            newIntent.tags = value.map((tag) => tag._id);
                            return newIntent;
                          });
                        }}
                        renderInput={(params) => (
                          <Box display="flex" flexDirection="row">
                            <TextField
                              {...params}
                              label="Tags"
                              variant="standard"
                              className="transition-message"
                              fullWidth
                            />
                          </Box>
                        )}
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
            {valuateQuickReplies() ? (
              <Grid item xs={1}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "30vw",
                    borderRadius: "5px",
                    padding: "10px",
                    marginTop: "2vh",
                  }}>
                  <div>
                    <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                      {t("quickReplies")}
                    </Typography>
                    <QuickReplies
                      userPermissions={!userPermissions}
                      quickReplies={{
                        quickReplies: intent.quickReplies,
                        quickRepliesDynamic: intent.quickRepliesDynamic,
                        quickRepliesName: intent.quickRepliesName,
                      }}
                      intents={intents}
                      isAddDisabled={isAddDisabled}
                      updateQuickReplies={(edit, error, errori, v) => {
                        setIntent((prevIntent) => {
                          const newIntent = { ...prevIntent };
                          newIntent.quickReplies = edit.static;
                          newIntent.quickRepliesDynamic =
                            edit.quickRepliesDynamic;
                          newIntent.quickRepliesName = edit.quickRepliesName;
                          return newIntent;
                        });
                        setValidQuickR(v);
                      }}></QuickReplies>
                  </div>
                </Box>
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={1}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "30vw",
                borderRadius: "5px",
                padding: "10px",
                marginTop: "2vh",
              }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                  {t("dateConditions")}
                </Typography>
                <div style={{ flexGrow: 1 }}></div>
                <Tooltip
                  title={
                    !userPermissions
                      ? t("intents:tooltipCondition")
                      : t("noPermissionTooltip")
                  }
                  sx={{
                    display: "flex",
                    marginLeft: "auto",
                    "&:hover": { color: "#4e8cff" },
                  }}>
                  <span>
                    <IconButton
                      disabled={userPermissions}
                      onClick={handleAddCondition}>
                      <AddIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
              <div className="conditions">
                {intent.conditions &&
                  intent.conditions.map((condition, index) => (
                    <div key={condition.id} className="condition">
                      <div className="condition-buttons">
                        <Tooltip
                          title={
                            !userPermissions ? "" : t("noPermissionTooltip")
                          }>
                          <span>
                            <IconButton
                              id="delete-condition-icon"
                              disabled={userPermissions}
                              onClick={() => {
                                setIntent((prevIntent) => {
                                  const newConditions = [
                                    ...prevIntent.conditions,
                                  ];
                                  newConditions.splice(index, 1);
                                  return {
                                    ...prevIntent,
                                    conditions: newConditions,
                                  };
                                });
                              }}>
                              <Delete
                                sx={
                                  !userPermissions
                                    ? {
                                      color: "deleteIcon.color",
                                    }
                                    : { color: "gray" }
                                }
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </div>
                      <InputLabel shrink>{t("intents:operator")}</InputLabel>
                      <Tooltip
                        title={
                          !userPermissions ? "" : t("noPermissionTooltip")
                        }>
                        <Select
                          id="date-operator"
                          fullWidth
                          disabled={userPermissions}
                          value={condition.op || dateOperations[0].value}
                          sx={{ marginBottom: "20px" }}
                          onChange={(e) => {
                            updateDateOp(e, index);
                          }}>
                          {dateOperations.map((item, index) => (
                            <MenuItem
                              name={item.name}
                              value={item.value}
                              key={index}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Tooltip>
                      {conditionsRender(condition, index)}
                      <Tooltip
                        title={
                          !userPermissions ? "" : t("noPermissionTooltip")
                        }>
                        <span>
                          <TextField
                            id={`condition_message_${index}`}
                            disabled={userPermissions}
                            label={t("intents:conditionMessage")}
                            fullWidth
                            sx={{ marginTop: "1vh", marginBottom: "2vh" }}
                            variant="standard"
                            value={condition.reply}
                            error={
                              errorsConditions[index] &&
                              errorsConditions[index].reply !== "" &&
                              errorsConditions[index].reply !== undefined
                            }
                            helperText={
                              errorsConditions[index] &&
                              errorsConditions[index].reply !== "" &&
                              errorsConditions[index].reply !== undefined
                                ? errorsConditions[index].reply
                                : ""
                            }
                            FormHelperTextProps={{
                              id: `helper_text_condition_message_${index}`,
                            }}
                            onChange={(e) => {
                              setIntent((prevIntent) => {
                                const newIntent = { ...prevIntent };
                                newIntent.conditions[index].reply =
                                  e.target.value;
                                return newIntent;
                              });
                            }}></TextField>
                        </span>
                      </Tooltip>
                    </div>
                  ))}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "20px",
        }}>
        <Tooltip
          title={!userPermissions ? "" : t("intents:noPermissionTooltip")}>
          <span>
            <Button
              id="intents-properties-button-save"
              variant="contained"
              disabled={
                hasErrorsConditions ||
                validQuickR ||
                hasErrors ||
                userPermissions
              }
              onClick={() => saveChanges(intent)}
              sx={{ marginLeft: "auto" }}>
              {t("intents:save")}
            </Button>
          </span>
        </Tooltip>
      </Box>
    </React.Fragment>
  );
};

export default Properties;
