import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Container } from "@smooth-dnd/react";
import SortableItem from "./SortableItem";
import { IconButton, Typography, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "./TextDnD.css";

/**
 * TextDnD component to manage a list of draggable items.
 *
 * @param {Object} props - Component props.
 * @param {string[]} [props.items=[]] - List of items.
 * @param {Function} props.setItems - Function to update the items.
 * @param {string} props.title - Title of the list.
 * @param {string} props.helperText - Helper text.
 * @param {boolean} props.error - Indicates if there is an error.
 * @param {boolean[]} props.errorArray - Array of booleans indicating errors.
 */

const TextDnD = ({
  items: initialItems = [],
  setItems: updateParent,
  title,
  helperText,
  error,
  errorArray,
}) => {
  const [localItems, setLocalItems] = useState(initialItems || []);

  useEffect(() => {
    setLocalItems(initialItems || []);
  }, [initialItems]);

  const addItem = useCallback(() => {
    const newItems = [...localItems, ""];
    setLocalItems(newItems);
    updateParent(newItems);
  }, [localItems, updateParent]);

  const updateItem = useCallback(
    (index, newText) => {
      setLocalItems((prev) => {
        if (prev[index] === newText) return prev;
        const updatedItems = [...prev];
        updatedItems[index] = newText;
        updateParent(updatedItems);
        return updatedItems;
      });
    },
    [updateParent],
  );

  const deleteItem = useCallback(
    (index) => {
      setLocalItems((prev) => {
        const updatedItems = [...prev];
        updatedItems.splice(index, 1);
        updateParent(updatedItems);
        return updatedItems;
      });
    },
    [updateParent],
  );

  const onDrop = useCallback(
    ({ removedIndex, addedIndex }) => {
      setLocalItems((prev) => {
        const newItems = [...prev];
        const [removedItem] = newItems.splice(removedIndex, 1);
        newItems.splice(addedIndex, 0, removedItem);
        updateParent(newItems);
        return newItems;
      });
    },
    [updateParent],
  );

  const renderedItems = useMemo(
    () =>
      localItems.map((item, index) => (
        <SortableItem
          key={index}
          index={index}
          text={item}
          updateItem={updateItem}
          deleteItem={deleteItem}
          error={errorArray ? errorArray[index] : false}
        />
      )),
    [localItems, updateItem, deleteItem, errorArray],
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        backgroundColor: "boxAnalytics.background",
        marginBottom: "10px",
      }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="subtitle1">{title}</Typography>
        <IconButton
          id="text-dnd-add-button"
          color="primary"
          onClick={addItem}
          style={{ marginBottom: "10px" }}
          disabled={error}>
          <AddIcon />
        </IconButton>
      </div>
      <Container
        onDrop={onDrop}
        dropPlaceholder={{
          animationDuration: 150,
          showOnTop: true,
          className: "cards-drop-preview",
        }}
        dragClass="card-ghost"
        dropClass="card-ghost-drop">
        {renderedItems}
      </Container>
      <Typography
        variant="caption"
        sx={{ color: error ? "error.main" : "text" }}>
        {!error && helperText}
      </Typography>
    </Box>
  );
};

TextDnD.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  setItems: PropTypes.func.isRequired,
  title: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  errorArray: PropTypes.arrayOf(PropTypes.bool),
};

export default React.memo(TextDnD);
