import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextDnDComponent from "../../../shared/components/TextDnD/TextDnD.component";

export default function AltMessage({ data, ...props }) {
  const [responseAlt, setResponseAlt] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (data.response && data.response.responseAlt) {
      setResponseAlt(data.response.responseAlt);
    }
  }, [data]);

  useEffect(() => {}, [responseAlt]);

  const updateMessageAlt = (newResponseAlt) => {
    setResponseAlt(newResponseAlt);

    props.updateAMessage(newResponseAlt);
  };

  return (
    <div>
      <TextDnDComponent
        name="msgs-alt"
        source="alt"
        title={t("intents:alternativeMessage")}
        fullWidth
        sx={{ marginTop: "15px", marginBottom: "25px" }}
        items={data.response?.responseAlt || data.responseAlt}
        setItems={updateMessageAlt}
        error={props.error}
        errorArray={props.errorArray}
        {...props}
      />
    </div>
  );
}
