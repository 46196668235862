import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  Slider,
  Tooltip,
  IconButton,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { post } from "../../../shared/http/httpService";
import AccordionDetails from "@mui/material/AccordionDetails";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { orderAlphabetically } from "../../../shared/helper/orderAlphabetically";
export const Ai = ({ data, userPermissions, pagesLean, agent }) => {
  const [hasAi, setHasAi] = useState(false);
  const [context, setContext] = useState("");
  const [instruction, setInstruction] = useState("");
  const [hasSuggestion, setHasSuggestion] = useState(false);
  const [suggestionText, setSuggestionText] = useState("");
  const [hasPdfUrl, setHasPdfUrl] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [hasTokens, setHasTokens] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [tokens, setTokens] = useState(500);
  const [hasTemperature, setHasTemperature] = useState(false);
  const [hasAbusiveLanguage, setHasAbusiveLanguage] = useState(false);
  const [abusiveTransferPage, setAbusiveTransferPage] = useState("");
  const [temperature, setTemperature] = useState(false);
  const [autoSync, setAutoSync] = useState(false);
  const { t } = useTranslation("ai");

  useEffect(() => {
    if (data.ai !== undefined) {
      setHasAi(data.ai.active);
      setContext(data.ai.context);
      setInstruction(data.ai.instruction);
      setHasSuggestion(data.ai.hasSuggestion || false);
      setSuggestionText(data.ai.suggestionText);
      setHasTokens(data.ai.hasTokens || false);
      setTokens(data.ai.tokens);
      setHasPdfUrl(data.ai.hasPdfUrl || false);
      setPdfUrl(data.ai.pdfUrl);
      setHasTemperature(data.ai.hasTemperature || false);
      setTemperature(data.ai.temperature);
      setAutoSync(data.ai.autoSync || false);
      setHasAbusiveLanguage(data.ai.abusiveLanguage || false);
      setAbusiveTransferPage(data.ai.abusiveTransferPage);
    } else {
      setHasAi(false);
      setContext("");
      setInstruction("");
      setHasSuggestion(false);
      setSuggestionText("");
      setHasTokens(false);
      setTokens(500);
      setHasPdfUrl(false);
      setPdfUrl("");
      setHasTemperature(false);
      setTemperature("");
      setAutoSync(false);
      setHasAbusiveLanguage(false);
      setAbusiveTransferPage("");
    }
  }, [data]);

  const updateEnable = (e) => {
    const active = e.target.checked;
    setHasAi(active);
    if (active) {
      data.ai = {
        active: active,
        context: context,
      };
    } else {
      data.ai = {
        active: false,
        context: "",
      };
    }
  };
  const updateSuggestion = (e) => {
    const active = e.target.checked;
    setHasSuggestion(active);
    if (data.ai) {
      data.ai.hasSuggestion = active;
      data.ai.suggestionText = suggestionText;
    } else {
      data.ai = {
        hasSuggestion: false,
        suggestionText: "",
      };
    }
  };
  const updatePdfUrl = (e) => {
    const active = e.target.checked;
    setHasPdfUrl(active);
    if (data.ai) {
      data.ai.hasPdfUrl = active;
      data.ai.pdfUrl = pdfUrl;
    } else {
      data.ai = {
        hasPdfUrl: false,
        pdfUrl: "",
      };
    }
  };
  const updateAiContext = (e) => {
    const newContext = e.target.value;
    setContext(newContext);

    if (data.ai) {
      data.ai.context = newContext;
    }
  };
  const updateAiInstruction = (e) => {
    const newInst = e.target.value;
    setInstruction(newInst);

    if (data.ai) {
      data.ai.instruction = newInst;
    }
  };
  const updateSuggestionText = (e) => {
    const newSuggestion = e.target.value;
    setSuggestionText(newSuggestion);

    if (data.ai) {
      data.ai.suggestionText = newSuggestion;
    }
  };

  const updateHasTokens = (e) => {
    const active = e.target.checked;
    setHasTokens(active);
    if (data.ai) {
      data.ai.hasTokens = active;
      data.ai.tokens = tokens;
    }
  };

  const updateHasTemperature = (e) => {
    const active = e.target.checked;
    setHasTemperature(active);
    if (data.ai) {
      data.ai.hasTemperature = active;
      data.ai.temperature = temperature;
    }
  };

  const updateAbusiveLanguage = (e) => {
    const active = e.target.checked;
    setHasAbusiveLanguage(active);
    if (data.ai) {
      data.ai.abusiveLanguage = active;
      data.ai.abusiveTransferPage = abusiveTransferPage;
    } else {
      data.ai = {
        abusiveLanguage: false,
        abusiveLanguageText: "",
      };
    }
  };

  const updatePdfUrlText = (e) => {
    const newUrl = e.target.value;
    setPdfUrl(newUrl);

    if (data.ai) {
      data.ai.pdfUrl = newUrl;
    }
  };
  const updateTokens = (e) => {
    const newTokens = e.target.value;
    setTokens(newTokens);
    if (data.ai) {
      data.ai.tokens = newTokens;
    }
  };

  const updateTemperature = (e) => {
    const newTemperature = e.target.value;
    setTemperature(newTemperature);
    if (data.ai) {
      data.ai.temperature = newTemperature;
    }
  };

  const updateAutoSync = (e) => {
    const autoSync = e.target.checked;
    setAutoSync(autoSync);
    if (data.ai) {
      data.ai.autoSync = autoSync;
    }
  };

  const updateTransferAiPage = async (value) => {
    const transferPage = value;
    setAbusiveTransferPage(transferPage);
    if (data.ai) {
      data.ai.abusiveTransferPage = transferPage;
    }
  };

  const handleKnowledgeBaseTrain = async () => {
    setIsLoading(true);
    await post("/essentials/core/knowledgeBase/train", {
      url: data.ai.pdfUrl,
      embedding_model: "openai",
    });
    setIsLoading(false);
    setIsSuccess(true);
  };

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 0.5,
      label: "0.5",
    },
    {
      value: 1,
      label: "1",
    },
  ];

  return (
    <div>
      <AccordionDetails>
        <Typography
          sx={{
            display: "inline-flex",
          }}>
          <FormControlLabel
            control={
              <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
                <span>
                  <Switch
                    onClick={updateEnable}
                    disabled={!userPermissions}
                    checked={hasAi}
                  />
                </span>
              </Tooltip>
            }
            label={!hasAi ? t("disabledButton") : t("enabledButton")}
          />
        </Typography>
        {hasAi && (
          <div className="accordion-rows">
            <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
              <TextField
                disabled={!userPermissions}
                id="ia-host"
                label={t("context")}
                variant="standard"
                placeholder={t("contextText")}
                multiline
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={context}
                onChange={updateAiContext}
                helperText={t("contextHelper")}
              />
              <TextField
                disabled={!userPermissions}
                id="ia-host"
                label={t("instruction")}
                variant="standard"
                placeholder={t("instructionText")}
                multiline
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={instruction}
                onChange={updateAiInstruction}
                helperText={t("instructiontHelper")}
              />
              {" "}
            </Tooltip>
            <Typography
              sx={{
                display: "",
              }}>
              <FormControlLabel
                control={
                  <Tooltip
                    title={!userPermissions ? t("noPermissionTooltip") : ""}>
                    <Switch
                      onClick={updateSuggestion}
                      disabled={!userPermissions}
                      checked={hasSuggestion}
                    />{" "}
                  </Tooltip>
                }
                label={
                  !hasSuggestion
                    ? t("suggestionsDisabled")
                    : t("suggestionsEnabled")
                }
              />
            </Typography>
          </div>
        )}
        {hasSuggestion && hasAi && (
          <div className="accordion-rows">
            <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
              <TextField
                disabled={!userPermissions}
                id="ia-host"
                label={t("suggestions")}
                variant="standard"
                placeholder={t("suggestionsText")}
                multiline
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={suggestionText}
                onChange={updateSuggestionText}
                helperText={t("suggestionsHelper")}
              />{" "}
            </Tooltip>
          </div>
        )}
        {hasAi && (
          <div className="accordion-rows">
            <Typography
              sx={{
                display: "",
              }}>
              <FormControlLabel
                control={
                  <Tooltip
                    title={!userPermissions ? t("noPermissionTooltip") : ""}>
                    <Switch
                      onClick={updateHasTokens}
                      disabled={!userPermissions}
                      checked={hasTokens}
                    />{" "}
                  </Tooltip>
                }
                label={!hasTokens ? t("tokensDisabled") : t("tokensEnabled")}
              />
            </Typography>
          </div>
        )}
        {hasTokens && hasAi && (
          <div className="accordion-rows">
            <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
              <TextField
                disabled={!userPermissions}
                id="ia-host"
                label="Tokens"
                variant="standard"
                placeholder={t("tokenText")}
                multiline
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={tokens}
                onChange={updateTokens}
                helperText={t("tokenHelper")}
              />
            </Tooltip>
          </div>
        )}
        {hasAi && (
          <div className="accordion-rows">
            <Typography
              sx={{
                display: "",
              }}>
              <FormControlLabel
                control={
                  <Tooltip
                    title={!userPermissions ? t("noPermissionTooltip") : ""}>
                    <Switch
                      onClick={updateHasTemperature}
                      disabled={!userPermissions}
                      checked={hasTemperature}
                    />{" "}
                  </Tooltip>
                }
                label={
                  !hasTemperature
                    ? t("temperatureDisabled")
                    : t("temperatureEnabled")
                }
              />
            </Typography>
          </div>
        )}
        {hasAi && hasTemperature && (
          <div className="accordion-rows">
            <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
              <span>
                <Slider
                  disabled={!userPermissions}
                  step={0.1}
                  valueLabelDisplay="auto"
                  marks={marks}
                  value={temperature}
                  onChange={updateTemperature}
                  min={0}
                  max={1}
                  sx={{ width: "90%", marginLeft: "5%" }}
                />
              </span>
            </Tooltip>
          </div>
        )}
        {hasAi && (
          <div className="accordion-rows">
            <Typography
              sx={{
                display: "",
              }}>
              <FormControlLabel
                control={
                  <Tooltip
                    title={!userPermissions ? t("noPermissionTooltip") : ""}>
                    <Switch
                      onClick={updateAbusiveLanguage}
                      disabled={!userPermissions}
                      checked={hasAbusiveLanguage}
                    />{" "}
                  </Tooltip>
                }
                label={
                  !hasAbusiveLanguage
                    ? t("abusiveLanguageDisabled")
                    : t("abusiveLanguageEnabled")
                }
              />
            </Typography>
          </div>
        )}
        {hasAbusiveLanguage && hasAi && (
          <div className="accordion-rows">
            <FormControl style={{ width: "93%", marginLeft: "2%" }}>
              <InputLabel id="destination-page-label">
                {t("destinationAiPage")}
              </InputLabel>
              <Select
                label={t("destinationAiPage")}
                variant="standard"
                className="destination-page"
                value={abusiveTransferPage ? abusiveTransferPage : ""}
                onChange={(e) => updateTransferAiPage(e.target.value)}
                fullWidth
                style={{ marginBottom: "15px" }}>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {pagesLean
                  ? orderAlphabetically(pagesLean)
                    .filter(
                      (item) => item.agent == agent && item._id != data.oid,
                    )
                    .map((item, index) => (
                      <MenuItem
                        name={
                          item.display_name ? item.display_name : item.name
                        }
                        value={item._id}
                        key={index}>
                        <ListItemText
                          primary={
                            item.display_name ? item.display_name : item.name
                          }
                        />
                      </MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>
          </div>
        )}
        {hasAi && (
          <div className="accordion-rows">
            <Typography
              sx={{
                display: "",
              }}>
              <FormControlLabel
                control={
                  <Tooltip
                    title={!userPermissions ? t("noPermissionTooltip") : ""}>
                    <Switch
                      onClick={updatePdfUrl}
                      disabled={!userPermissions}
                      checked={hasPdfUrl}
                    />{" "}
                  </Tooltip>
                }
                label={
                  !hasPdfUrl ? t("pdfQueryDisabled") : t("pdfQueryEnabled")
                }
              />
            </Typography>
          </div>
        )}
        {hasPdfUrl && hasAi && (
          <div className="accordion-rows">
            <Tooltip title={!userPermissions ? t("noPermissionTooltip") : ""}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  disabled={!userPermissions}
                  id="ia-host"
                  label={t("urlLabel")}
                  variant="standard"
                  placeholder={t("pdfText")}
                  multiline
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={pdfUrl}
                  onChange={updatePdfUrlText}
                  helperText={t("pdfHelper")}
                />
                <Tooltip arrow title={t("refreshData")}>
                  <IconButton
                    disabled={!pdfUrl || isLoading || isSuccess}
                    onClick={() => {
                      handleKnowledgeBaseTrain();
                    }}>
                    {isLoading ? <CircularProgress size={24} /> : isSuccess ? <SuccessIcon style={{ color: "green" }} /> : <RefreshIcon />}
                  </IconButton>
                </Tooltip>
                <Checkbox 
                  title={t("autoSync")}
                  disabled={!pdfUrl}
                  checked={autoSync}
                  onClick={updateAutoSync}>
                </Checkbox>
              </div>
            </Tooltip>
          </div>
        )}
      </AccordionDetails>
    </div>
  );
};
