import React, { useState, useMemo } from "react";
import { get } from "../../shared/http/httpService";
import { VictoryChart, VictoryBar, VictoryTooltip, VictoryAxis } from "victory";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ChartTheme from "../theme/theme";
import { getDate } from "../../shared/helper/timeHandler";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import DateRange from "../../shared/components/DateRange";
import dayjs from "dayjs";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";

import {
  tableLightTheme,
  tableDarkTheme,
} from "../../shared/theming/table.theme";

const useStyles = styled(() => ({
  container: {
    display: "flex",
    height: "100vh",
  },
  zIndex0: {
    zIndex: "0",
  },
  buttonContainer: {
    alignSelf: "center",
  },
  button: {
    height: "40px",
    width: "100px",
    backgroundColor: "#D9D9D9",
    color: "black",
    borderRadius: "5px",
  },
}));

export default function LoogedPerDay() {
  const classes = useStyles();
  let chartTheme = ChartTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const theme = useSelector((state) => state.theme);
  const [valid, setValid] = useState(true);
  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );

  const columns = useMemo(
    () => [
      {
        header: t("usersPerDay:user"),
        accessorFn: (original) => original.user,
        id: "user",
      },
      {
        header: t("usersPerDay:username"),
        accessorFn: (original) => original.username,
        id: "username",
      },
      {
        header: t("usersPerDay:date"),
        accessorFn: (original) => original.date,
        id: "date",
      },
    ],
    [t],
  );

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("/");
    return new Date(year, month - 1, day); // month-1 because in JS months range from 0-11
  };

  const fetchDataConversation = async () => {
    try {
      const loggedPerDayTmp = await get("/report/loggedPerDay", {
        startDate: dayjs(startDate).toDate(),
        endDate: dayjs(endDate).toDate(),
        timezone: startDate.format("Z"),
      });

      // Modification for chart - sorted by date
      const newData = loggedPerDayTmp.graphic
        .sort((a, b) => parseDate(a.date) - parseDate(b.date))
        .map((item) => ({
          x: item.date,
          y: item.count,
          label: `${item.count} users logged on the day: ${item.date}`,
        }));

      // Modification for table - sorted by date
      const newDataTable = loggedPerDayTmp.table
        .sort((a, b) => parseDate(getDate(a.date)) - parseDate(getDate(b.date)))
        .map((row) => ({
          user: row.user,
          date: getDate(row.date),
          username: row.user,
        }));

      setDataTable(newDataTable);
      setData(newData);
    } catch (error) {
      if (error.status === 202) {
        // Query is in progress, display a snackbar message
        enqueueSnackbar(t("analytics:timeoutHandlingReport"), {
          variant: "error",
        });
      } else {
        // Handle other errors
        console.error("Error fetching users per day:", error);
        throw error;
      }
    }
  };

  function handleDate(dates) {
    if (dates.startDate !== null && dates.endDate !== null && dates.valid) {
      setValid(dates.valid);
      setStartDate(dates.startDate);
      setEndDate(dates.endDate);
    } else {
      setValid(dates.valid);
    }
  }

  return (
    <Container maxwidth="lg">
      <div>
        <Paper
          style={{
            marginBottom: "15px",
            paddingTop: "10px",
            paddingBottom: "15px",
          }}>
          <Typography variant="h4">{t("analytics:usersPerDay")}</Typography>
          <br></br>
          <Grid container justify="space-between">
            <DateRange
              startDate={startDate}
              endDate={endDate}
              onDateSelected={handleDate}
            />
            <Grid
              item
              xs={2}
              className={classes.buttonContainer}
              sx={{ alignItems: "center" }}>
              <Button
                id="fetchButton"
                className={classes.button}
                variant="contained"
                color="default"
                size="medium"
                disabled={!valid}
                sx={{
                  height: "40px",
                  width: "130px",
                  borderRadius: "5px",
                }}
                onClick={fetchDataConversation}>
                {t("analytics:fetch")}
              </Button>
            </Grid>
          </Grid>
          <VictoryChart
            responsive={false}
            height={300}
            width={400}
            animate={{
              duration: 500,
              onLoad: { duration: 200 },
            }}
            theme={chartTheme}
            domainPadding={{ x: 30 }}
            padding={80}>
            <VictoryBar
              barRatio={1}
              cornerRadius={{ top: 3 }}
              style={{
                data: {
                  fill: "#B474DC",
                },
                labels: {
                  fontSize: 5,
                  fill: "black",
                },
              }}
              alignment="middle"
              labelComponent={
                <VictoryTooltip
                  cornerRadius={1}
                  pointerLength={0}
                  flyoutStyle={{
                    stroke: "grey",
                    backgroundColor: "red",
                  }}
                />
              }
              data={data}
            />
            <VictoryAxis
              label={t("usersPerDay:date")}
              style={{
                axisLabel: { padding: 30 },
              }}
            />
            <VictoryAxis
              dependentAxis
              label={t("usersPerDay:amountUsers")}
              style={{
                axisLabel: { padding: 50 },
              }}
            />
          </VictoryChart>
        </Paper>
        <Paper>
          <div style={{ maxWidth: "100%" }}>
            <Typography variant="h4" sx={{ mb: "1rem" }}>
              <br></br>
              {t("usersPerDay:loggedUserTable")}
            </Typography>
            <ThemeProvider
              theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
              <MaterialReactTable
                localization={
                  i18n.language === "ES"
                    ? MRT_Localization_ES
                    : MRT_Localization_EN
                }
                columns={columns}
                data={dataTable}
                style={{
                  paddingLeft: "30px",
                  paddingRight: "30px",
                }}
              />
            </ThemeProvider>
          </div>
        </Paper>
      </div>
    </Container>
  );
}
