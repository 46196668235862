import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
  Box,
  Tooltip,
} from "@mui/material";
import TimeRange from "../shared/components/TimeRange";
import { useSnackbar } from "notistack";
import { get, post, put, remove } from "../shared/http/httpService";
import dayjs from "dayjs";
import { orderAlphabeticallyWithAttribute } from "../shared/helper/orderAlphabetically";
import { defaultAgent } from "../shared/helper/agentsHelper";
import { useTranslation } from "react-i18next";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import "dayjs/locale/es";
import "dayjs/locale/en";
import { MaterialReactTable } from "material-react-table";
import { IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { HolidaysModal } from "./Components/HolidaysModal.component";
import { DeleteRowModal } from "../shared/helper/tableHelper";
import { tableLightTheme, tableDarkTheme } from "../shared/theming/table.theme";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";

const WorkingDays = () => {
  const { t, i18n } = useTranslation("workingDays");
  const theme = useSelector((state) => state.theme);
  const { enqueueSnackbar } = useSnackbar();
  const [accountNames, setAccountNames] = useState([]);
  const [accountSelected, setAccountSelected] = useState({});
  const [accountSelectedName, setAccountSelectedName] = useState("");
  const [editWeek, setEditWeek] = useState([]);
  const [validTimeRange, setValidTimeRange] = useState(true);
  const [validCalendar, setValidCalendar] = useState(false);
  const [holidays, setHolidays] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("new");
  const [deleteModal, setDeleteModal] = useState(false);
  const [editRow, setEditRow] = useState({});

  let auth = useSelector((state) => state.auth);
  let [userPermissionsWorkingDays, setUserWorkingDaysPermissions] =
    React.useState(false);
  let [userPermissionsHolidays, setUserHolidaysPermissions] =
    React.useState(false);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasWorkingDaysPermissions = user.permissions.some(
      (permission) =>
        permission.name === "workingDays" && permission.fullAccess === true,
    );
    let hasHolidayPermissions = user.permissions.some(
      (permission) =>
        permission.name === "holidays" && permission.fullAccess === true,
    );
    setUserWorkingDaysPermissions(hasWorkingDaysPermissions);
    setUserHolidaysPermissions(hasHolidayPermissions);
  };

  const [weekDays, setWeekDays] = useState([
    {
      name: "Monday",
      work: false,
      startTime: undefined,
      endTime: undefined,
      tz: 0,
      valid: false,
    },
    {
      name: "Tuesday",
      work: false,
      startTime: undefined,
      endTime: undefined,
      tz: 0,
      valid: false,
    },
    {
      name: "Wednesday",
      work: false,
      startTime: undefined,
      endTime: undefined,
      tz: 0,
      valid: false,
    },
    {
      name: "Thursday",
      work: false,
      startTime: undefined,
      endTime: undefined,
      tz: 0,
      valid: false,
    },
    {
      name: "Friday",
      work: false,
      startTime: undefined,
      endTime: undefined,
      tz: 0,
      valid: false,
    },
    {
      name: "Saturday",
      work: false,
      startTime: undefined,
      endTime: undefined,
      tz: 0,
      valid: false,
    },
    {
      name: "Sunday",
      work: false,
      startTime: undefined,
      endTime: undefined,
      tz: 0,
      valid: false,
    },
  ]);

  useEffect(() => {
    getUserPermisions();
    getAgents();
    getHolidays();
    setEditWeek(weekDays);
  }, []);

  useEffect(() => {
    setSchedule();
  }, [accountSelected, accountNames]);

  useEffect(() => {
    if (editWeek !== weekDays && validTimeRange) {
      setValidCalendar(true);
    } else {
      setValidCalendar(false);
    }
  }, [weekDays]);

  // gets agents data and sets states, Sets the account in use if provided
  const getAgents = async (actualAccount = undefined) => {
    get("/essentials/data/agents")
      .then((data) => {
        orderAlphabeticallyWithAttribute(data.agents, "display_name");
        const agent = actualAccount
          ? data.agents.find((agent) => agent.name === actualAccount.name)
          : defaultAgent(data.agents);
        setAccountSelected(agent);
        setAccountSelectedName(agent.name);
        setAccountNames([...data.agents]);
      })
      .catch(() => {
        enqueueSnackbar(t("workingDays:errorGettingSchedule"), {
          variant: "error",
        });
      });
  };

  const handleChangeAccount = async (accountName) => {
    let accountSelected = accountNames.find(
      (account) => account.name === accountName,
    );
    setAccountSelectedName(accountSelected.name);
    setAccountSelected({ ...accountSelected });
  };

  // sets the weekdays variable, which is used to set the day cards
  const setSchedule = async () => {
    // checks it the selected account contains a schedule
    const schedule = accountSelected.working_days
      ? accountSelected.working_days.schedule
        ? accountSelected.working_days.schedule
        : []
      : [];
    const editWeekDays = weekDays;

    // sets the weekday var attributes
    editWeekDays.forEach((day) => {
      day.work = false;
      day.startTime = undefined;
      day.endTime = undefined;
      day.valid = true;
    });

    // if the schedule isn't empty, fill weekdays var with agent conditions
    if (schedule.length !== 0) {
      schedule.forEach((condition) => {
        condition.days.forEach((day, index) => {
          if (day !== "") {
            editWeekDays[index].work = true;
            editWeekDays[index].endTime = condition.endTime;
            editWeekDays[index].startTime = condition.startTime;

            if (
              dayjs(condition.endTime, "HH:mm a").isBefore(
                dayjs(condition.startTime, "HH:mm a"),
              )
            ) {
              editWeekDays[index].valid = false;
            }
          }
        });
      });
    }
    if (!(editWeekDays === weekDays)) {
      editWeekDays.some((day) => day.valid === false)
        ? setValidTimeRange(false)
        : setValidTimeRange(true);
    } else {
      setValidTimeRange(false);
    }
    setWeekDays([...editWeekDays]);
  };

  const handleTimeRange = (e, index) => {
    let edit = weekDays;

    edit[index].startTime = e.startTime;
    edit[index].endTime = e.endTime;
    edit[index].valid = e.valid;

    edit.some((day) => day.valid === false)
      ? setValidTimeRange(false)
      : setValidTimeRange(true);
    setWeekDays([...edit]);
  };

  const handleIsWorkDay = (e, index) => {
    let edit = weekDays;
    edit[index].work = !edit[index].work;
    if (edit[index].work) {
      edit[index].startTime = dayjs()
        .hour(0)
        .minute(0)
        .second(0)
        .format("HH:mm:ss");
      edit[index].endTime = dayjs()
        .hour(23)
        .minute(0)
        .second(0)
        .format("HH:mm:ss");
    }
    edit.some((day) => day.valid === false)
      ? setValidTimeRange(false)
      : setValidTimeRange(true);
    setWeekDays([...edit]);
  };

  const updateAgentSchedule = () => {
    const newTimeConditions = handleConditions();

    const body = {
      _id: accountSelected._id,
      name: accountSelected.name,
      conditions: newTimeConditions,
    };
    post("/agents/schedule", body)
      .then(async () => {
        enqueueSnackbar(t("workingDays:scheduleUpdated"), {
          variant: "success",
        });
        getAgents(accountSelected);
      })
      .catch(() => {
        enqueueSnackbar(t("workingDays:errorUpdatingSchedule"), {
          variant: "error",
        });
      });
  };

  // creates the schedule conditions
  const handleConditions = () => {
    let newConditions = [];
    let emptyDays = [];

    weekDays.forEach((day) => {
      // look for any condition with the same starttime and endtime
      let existingCondition = newConditions.find(
        (item) =>
          item.startTime === day.startTime && item.endTime === day.endTime,
      );

      if (existingCondition) {
        // if the workday checkbox is checked
        if (day.work) {
          // Adds the day in the existing condition days array
          existingCondition.days.push(day.name.slice(0, 2));
          // conver the empty day space with '' in all conditions except the existing one
          newConditions.forEach((condition) =>
            condition.startTime === existingCondition.startTime &&
            condition.endTime === existingCondition.endTime
              ? {}
              : condition.days.push(""),
          );
        } else {
          // if not, cover the empty day space with '' in all conditions
          newConditions.forEach((condition) => condition.days.push(""));
        }
      } else {
        // not existing condition, create another one
        // if the workday checkbox is checked
        if (day.work) {
          let newCondition = {
            startTime: day.startTime,
            endTime: day.endTime,
            tz: dayjs().utcOffset(),
          };
          // set de new condition days array with a copy of the emptyDays array
          newCondition.days = [...emptyDays];
          // adds the new day to the days array
          newCondition.days.push(day.name.slice(0, 2));
          // add a new condition and an empty day to the other conditions
          newConditions.push(newCondition);
          newConditions.forEach((condition) =>
            condition.startTime === newCondition.startTime &&
            condition.endTime === newCondition.endTime
              ? {}
              : condition.days.push(""),
          );
        } else {
          // if it's not an existing condition and it's not a workday, add in each condition days array an empty day('')
          newConditions.forEach((condition) => condition.days.push(""));
        }
      }
      emptyDays.push("");
    });
    return newConditions;
  };

  const getHolidays = () => {
    get("/holidays")
      .then(async (data) => {
        //conver holydays date to dd/mm/yyyy format
        data.holidays.forEach((holiday) => {
          holiday.date = dayjs(holiday.date).format("DD/MM");
        });
        setHolidays(data.holidays);
      })
      .catch(() => {
        enqueueSnackbar(t("workingDays:errorGettingHolidays"), {
          variant: "error",
        });
      });
  };

  const newHoliday = (row) => {
    const body = {
      name: row.name,
      date: dayjs(row.date, "DD/MM").format(),
      tz: dayjs().utcOffset(),
    };
    if (!body.date || body.date === undefined || body.date === "Invalid date") {
      body.date = dayjs().format();
    }
    post("/holidays", body)
      .then(() => {
        enqueueSnackbar(t("workingDays:holidayAdded"), {
          variant: "success",
        });
        getHolidays();
      })
      .catch(() => {
        enqueueSnackbar(t("workingDays:errorCreatingHoliday"), {
          variant: "error",
        });
      });
  };

  const updateHoliday = async (row, values) => {
    const body = {
      _id: row.original._id,
      name: values.name,
      date: dayjs(values.date, "DD/MM").format(),
      tz: dayjs().utcOffset(),
    };
    put("/holidays", body)
      .then(async () => {
        enqueueSnackbar(t("workingDays:holidayUpdated"), {
          variant: "success",
        });
        getHolidays();
      })
      .catch(() => {
        enqueueSnackbar(t("workingDays:errorUpdatingHoliday"), {
          variant: "error",
        });
      });
  };

  const deleteHoliday = (row) => {
    remove("/holidays", { _id: row.original._id, name: row.original.name })
      .then(async () => {
        //status 200
        enqueueSnackbar(t("workingDays:holidayDeleted"), {
          variant: "success",
        });
        getHolidays();
      })
      .catch((error) => {
        enqueueSnackbar(
          error.response.status === 500
            ? t("workingDays:errorHolidayInUse")
            : t("workingDays:errorDeletingHoliday"),
          {
            variant: "error",
          },
        );
      });
  };

  const columns = useMemo(
    () => [
      {
        header: t("workingDays:name"),
        accessorKey: "name",
        size: 300,
      },
      {
        header: t("workingDays:date"),
        accessorKey: "date",
        size: 300,
        sortingFn: (rowA, rowB, columnId) => {
          const dateA = dayjs(rowA.getValue(columnId), "DD/MM");
          const dateB = dayjs(rowB.getValue(columnId), "DD/MM");
          return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
        },
        Cell: ({ cell }) => <span>{cell.getValue()}</span>,
      },
    ],
    [i18n.language],
  );

  return (
    <Container
      maxwidth={false}
      style={{ maxWidth: "100%", minWidth: "70%" }}
      disableGutters>
      <Paper>
        <div>
          <Box
            display="flex"
            sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <h2
              style={{
                marginLeft: "40px",
              }}>
              {t("workingDays:schedule")}
            </h2>
            <div
              style={{
                display: "inline",
                margin: "45px",
                justifyContent: "end",
              }}>
              <InputLabel
                id="agent-label"
                sx={{
                  display: "inline",
                  height: "fit-content",
                  marginRight: "15px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: "20px",
                  minWidth: "100px",
                }}>
                {t("workingDays:agent")}
              </InputLabel>
              <FormControl variant="standard">
                <Select
                  id="combo-agent"
                  defaultValue={accountSelectedName}
                  value={accountSelectedName}
                  onChange={(e) => handleChangeAccount(e.target.value)}
                  sx={{
                    height: "fit-content",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}>
                  {accountNames?.map((item, index) => (
                    <MenuItem
                      name={item.display_name}
                      value={item.name}
                      key={index}
                      id={`menu-item-${index}`}>
                      {item.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Box>
        </div>
        <div>
          {weekDays.map((day, index) => (
            <Card
              key={index}
              raised
              sx={{
                width: "12%",
                minWidth: "200px",
                minHeight: "338px",
                margin: "15px",
                display: "inline-block",
              }}>
              <CardHeader
                title={t(
                  `workingDays:${day.name.toLocaleLowerCase()}`,
                )}></CardHeader>
              <CardContent>
                <FormControlLabel
                  style={{
                    marginBottom: "15px",
                    alignItems: "right",
                  }}
                  label={t("workingDays:workdayCheck")}
                  labelPlacement="end"
                  control={
                    <Tooltip
                      title={
                        userPermissionsWorkingDays
                          ? ""
                          : t("noPermissionTooltip")
                      }>
                      <span>
                        <Checkbox
                          checked={day.work}
                          disabled={!userPermissionsWorkingDays}
                          onChange={(e) => {
                            handleIsWorkDay(e, index);
                          }}></Checkbox>
                      </span>
                    </Tooltip>
                  }></FormControlLabel>
                <TimeRange
                  startTime={day.work ? day.startTime : "00:00:00"}
                  endTime={day.work ? day.endTime : "23:00:00"}
                  placeholder="hh:mm"
                  disabled={!day.work || !userPermissionsWorkingDays}
                  orientation={true}
                  onTimeSelected={(e) => handleTimeRange(e, index)}></TimeRange>
              </CardContent>
            </Card>
          ))}
        </div>
        <Box display="flex" justifyContent="flex-end">
          <Tooltip
            title={
              userPermissionsWorkingDays
                ? ""
                : t("workingDays:noPermissionTooltip")
            }>
            <span>
              <Button
                style={{ margin: "10px" }}
                size="large"
                disabled={!validCalendar || !userPermissionsWorkingDays}
                onClick={() => updateAgentSchedule()}>
                {t("workingDays:save")}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Paper>
      <div style={{ marginTop: "40px" }}>
        <ThemeProvider
          theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
          <MaterialReactTable
            className="table"
            sx={{ backgroundColor: "table.background" }}
            data={holidays ? holidays : []}
            columns={columns}
            enableRowActions
            positionActionsColumn="last"
            enableColumnOrdering
            onEditingRowSave={updateHoliday}
            enableGlobalFilter={false}
            editDisplayMode="modal"
            enableDelete
            enableEditing={true}
            localization={
              i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN
            }
            renderRowActions={({ row }) => (
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <Tooltip
                  arrow
                  placement="left"
                  title={
                    userPermissionsHolidays
                      ? t("editButton")
                      : t("noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      id="edit-icon"
                      onClick={() => {
                        setModalType("edit");
                        setOpenModal(true);
                        setEditRow(row);
                      }}
                      disabled={!userPermissionsHolidays}>
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    userPermissionsHolidays
                      ? t("deleteButton")
                      : t("noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      id="delete-icon"
                      sx={{ color: "red" }}
                      onClick={() => {
                        setEditRow(row);
                        setDeleteModal(true);
                      }}
                      disabled={!userPermissionsHolidays}>
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
            renderTopToolbarCustomActions={() => (
              <Tooltip
                title={userPermissionsHolidays ? "" : t("noPermissionTooltip")}>
                <span>
                  <Button
                    className="mrt-create-new-account-button"
                    id="new_holiday_button"
                    onClick={() => {
                      setModalType("new");
                      setOpenModal(true);
                    }}
                    variant="contained"
                    disabled={!userPermissionsHolidays}>
                    {t("workingDays:newHoliday")}
                  </Button>
                </span>
              </Tooltip>
            )}></MaterialReactTable>
        </ThemeProvider>
        <HolidaysModal
          columns={columns}
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={holidays}
          editRow={editRow}
          onSubmit={(values) => {
            if (modalType === "edit") {
              updateHoliday(editRow, values);
            } else {
              newHoliday(values);
            }
            setOpenModal(false);
          }}
          modalType={modalType}
        />
        <DeleteRowModal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          onDelete={() => {
            deleteHoliday(editRow);
            setDeleteModal(false);
          }}
          title={t("workingDays:titleDeleteModal")}
        />
      </div>
    </Container>
  );
};

export default WorkingDays;
