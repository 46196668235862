import React from "react";
import { Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const compareValues = (key, value1, value2) => {
  if (key === "responseAnimated" || key === "responseImagePosition") {
    if (value1 === undefined) value1 = false;
    if (value2 === undefined) value2 = false;
  } else {
    if (value1 === undefined) value1 = "";
    if (value2 === undefined) value2 = "";
  }
  if (typeof value1 === "object" && typeof value2 === "object") {
    return JSON.stringify(value1) !== JSON.stringify(value2);
  }
  return value1 !== value2;
};

const ResponseChanges = ({ response = {}, originalResponse = {} }) => {
  const { t } = useTranslation();
  const responseKeys = [
    "responseMessage",
    "responseAlt",
    "responseAnimated",
    "responseImageAdapter",
    "responseImageType",
    "responseImagePosition",
  ];

  return responseKeys.map((key) => {
    const originalValue = Array.isArray(originalResponse[key])
      ? originalResponse[key]
      : [originalResponse[key]];
    const newValue = Array.isArray(response[key])
      ? response[key]
      : [response[key]];

    return (
      <div key={key}>
        {newValue.some((value, index) =>
          compareValues(key, value, originalValue[index]),
        ) && (
          <>
            <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
              {t(key)}:
            </Typography>
            {newValue.map((value, index) => {
              if (compareValues(key, value, originalValue[index])) {
                return (
                  <div key={`${key}-${index}`}>
                    <Typography
                      sx={{
                        marginLeft: 2,
                        fontSize: "14px",
                        color: "tracesName.delete",
                        textDecoration: "line-through",
                      }}>
                      {originalValue[index] !== undefined
                        ? originalValue[index].toString()
                        : "N/A"}
                    </Typography>
                    <Typography
                      sx={{
                        marginLeft: 2,
                        fontSize: "14px",
                        color: "tracesName.new",
                      }}>
                      {value.toString()}
                    </Typography>
                    {index < newValue.length - 1 && (
                      <Divider
                        sx={{
                          marginTop: 1,
                          marginBottom: 1,
                          marginLeft: 2,
                          marginRight: 2,
                        }}
                      />
                    )}
                  </div>
                );
              }
              return null;
            })}
          </>
        )}
      </div>
    );
  });
};

export default ResponseChanges;
