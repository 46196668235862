import Paper from "@mui/material/Paper";
import React, { useState, useMemo } from "react";
import { get } from "../../shared/http/httpService";
import { Box, Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import DateRange from "../../shared/components/DateRange";
import dayjs from "dayjs";
import {
  tableLightTheme,
  tableDarkTheme,
} from "../../shared/theming/table.theme";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
// ExcelPlugin
import { mkConfig, generateCsv, download } from "export-to-csv";

const useStyles = styled(() => ({
  container: {
    display: "flex",
    height: "100vh",
  },
  zIndex0: {
    zIndex: "0",
  },
  buttonContainer: {
    alignSelf: "end",
  },
  button: {
    marginLeft: "10px",
    marginRight: "10px",
    backgroundColor: "#D9D9D9",
    color: "black",
  },
}));

const MonthInteractions = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();

  const classes = useStyles();
  const [valid, setValid] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [sumWhatsapp, setSumWhatsapp] = useState(0);
  const [sumFacebook, setSumFacebook] = useState(0);
  const [sumWeb, setSumWeb] = useState(0);
  const theme = useSelector((state) => state.theme);
  const [sumWebchat, setSumWebchat] = useState(0);
  const [sumInstagram, setSumInstagram] = useState(0);
  const [sumUndefined, setSumUndefined] = useState(0);
  const [sumMsTeams, setSumMsTeams] = useState(0);

  const channelMappings = {
    WHATSAPP: "WhatsApp",
    MESSENGER: "Facebook",
    WEB: "Web",
    INSTAGRAMDIRECT: "Instagram",
    WEBCHAT: "Webchat",
    msteams: "Teams",
  };

  const channelFilterOptions = Object.keys(channelMappings)
    .map((key) => ({
      value: key,
      text: channelMappings[key],
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  // eslint-disable-next-line no-unused-vars
  const filterPetitionType = ["api", "message", "media"];

  const petitionTypeMappings = {
    api: "API",
    message: "Message",
    media: "Media",
  };

  const petitionTypeFilterOptions = filterPetitionType.map((value) => ({
    value,
    text: petitionTypeMappings[value],
  }));

  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)),
  );
  const [fileName, setFileName] = useState(
    t("analytics:interactionsPerPeriodFile") +
      getParsedDate(startDate) +
      t("analytics:to") +
      getParsedDate(endDate),
  );

  const columns = useMemo(
    () => [
      {
        header: t("monthInteractions:origin"),
        accessorKey: "user",
        size: 150,
      },
      {
        header: "Darwin Chat ID",
        accessorKey: "darwinChatID",
        size: 150,
      },
      {
        header: t("monthInteractions:idConversation"),
        accessorKey: "room",
        size: 150,
      },
      {
        header: t("monthInteractions:channel"),
        accessorFn: (original) => original.channel,
        filterVariant: "multi-select",
        filterSelectOptions: channelFilterOptions,
        Cell: ({ cell }) => channelMappings[cell.getValue()],
        size: 150,
        filterFn: (row, id, filter) => {
          if (filter.length === 0) {
            return true;
          }
          return filter.includes(row.original.channel);
        },
      },
      {
        header: t("monthInteractions:interactions"),
        accessorFn: (original) => original.count,
        id: "count",
        size: 100,
      },
      {
        header: t("monthInteractions:petitionType"),
        accessorFn: (original) => original.type,
        id: "type",
        filterVariant: "multi-select",
        filterSelectOptions: petitionTypeFilterOptions,
        Cell: ({ cell }) => petitionTypeMappings[cell.getValue()],
        size: 75,
      },
      {
        header: t("monthInteractions:page"),
        accessorFn: (original) => original.page,
        id: "page",
      },
      {
        header: t("monthInteractions:agent"),
        accessorKey: "agent",
        id: "agent",
      },
    ],
    [t],
  );

  const fetchInteractionsMonth = async () => {
    try {
      const data = await get("/report/interactionsMonth", {
        startDate: dayjs(startDate).toDate(),
        endDate: dayjs(endDate).toDate(),
        timezone: startDate.format("Z"),
      });
      setTableData(data.table);
      let sumWhatsapp = 0;
      let sumFacebook = 0;
      let sumWeb = 0;
      let sumWebchat = 0;
      let sumInstagram = 0;
      let sumUndefined = 0;
      let sumMsTeams = 0;

      data.table.forEach(function (item) {
        if (item.channel === "WHATSAPP") sumWhatsapp = sumWhatsapp + item.count;
        if (item.channel === "MESSENGER")
          sumFacebook = sumFacebook + item.count;
        if (item.channel === "WEB") sumWeb = sumWeb + item.count;
        if (item.channel === "INSTAGRAMDIRECT")
          sumInstagram = sumInstagram + item.count;
        if (item.channel === "WEBCHAT") sumWebchat = sumWebchat + item.count;
        if (item.channel == null) sumUndefined = sumUndefined + item.count;
        item.destino = item.room === item.user ? "system" : item.room;
        if (item.channel === "msteams") {
          sumMsTeams = sumMsTeams + item.count;
        }
      });
      setTotal(
        sumWhatsapp +
          sumFacebook +
          sumWeb +
          sumInstagram +
          sumWebchat +
          sumUndefined +
          sumMsTeams,
      );
      setSumWhatsapp(sumWhatsapp);
      setSumFacebook(sumFacebook);
      setSumWeb(sumWeb);
      setSumWebchat(sumWebchat);
      setSumInstagram(sumInstagram);
      setSumUndefined(sumUndefined);
      setSumMsTeams(sumMsTeams);
    } catch (error) {
      if (error.status === 202) {
        enqueueSnackbar(t("analytics:timeoutHandlingReport"), {
          variant: "error",
        });
      } else {
        // Handle other errors
        console.error("Error fetching month interactions:", error);
        throw error;
      }
    }
  };

  function getParsedDate(date) {
    return dayjs(date).format("DD-MM-YYYY");
  }

  function handleDate(dates) {
    if (dates.startDate !== null && dates.endDate !== null && dates.valid) {
      setValid(dates.valid);
      setStartDate(dates.startDate);
      let newEndDate = new Date(dates.endDate);

      setEndDate(newEndDate);

      setFileName(
        t("analytics:interactionsPerPeriodFile") +
          t("analytics:from") +
          getParsedDate(dates.startDate) +
          t("analytics:to") +
          getParsedDate(newEndDate),
      );
    } else {
      setValid(dates.valid);
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    localization:
      i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN,
    className: classes.zIndex0,
    style: {
      paddingLeft: "30px",
      paddingRight: "30px",
      display: "block",
    },
  });

  const handleExportRows = (rows) => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
      filename: fileName,
    });
    const rowData = rows.map((row) => {
      return {
        [t("monthInteractions:origin")]: row.original.user,
        "ID Darwin Chat": row.original.darwinChatID,
        [t("monthInteractions:idConversation")]: row.original.destino,
        [t("monthInteractions:channel")]: row.original.channel,
        [t("monthInteractions:interactions")]: row.original.count,
        [t("monthInteractions:petitionType")]: row.original.type,
        [t("monthInteractions:page")]: row.original.page,
        [t("monthInteractions:agent")]: row.original.agent,
      };
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig, fileName)(csv);
  };

  return (
    <div>
      <Paper
        style={{
          marginBottom: "15px",
          borderRadius: "15px",
        }}>
        <Box
          sx={{
            p: 3,
            borderRadius: "15px",
            backgroundColor: "boxAnalytics.background",
          }}>
          <Typography variant="h4">
            {t("monthInteractions:interactionPerPeriod")}
          </Typography>
          <Grid container>
            <Grid item xs={12} md={3} display="flex">
              <DateRange
                startDate={startDate}
                endDate={endDate}
                onDateSelected={handleDate}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              display="flex"
              flexWrap="wrap"
              justifyContent="right"
              alignItems="center">
              <span
                style={{
                  color: "red",
                  marginRight: "15px",
                  marginLeft: "15px",
                }}>
                {t("monthInteractions:totalInteractions")}: {total}
              </span>
              <span
                style={{
                  marginRight: "15px",
                  marginLeft: "15px",
                }}>
                Whatsapp: {sumWhatsapp}
              </span>
              <span
                style={{
                  marginRight: "15px",
                  marginLeft: "15px",
                }}>
                Facebook: {sumFacebook}
              </span>
              <span
                style={{
                  marginRight: "15px",
                  marginLeft: "15px",
                }}>
                Instagram: {sumInstagram}
              </span>
              <span
                style={{
                  marginRight: "15px",
                  marginLeft: "15px",
                }}>
                Web: {sumWeb}
              </span>
              <span
                style={{
                  marginRight: "15px",
                  marginLeft: "15px",
                }}>
                Webchat: {sumWebchat}
              </span>
              <span
                style={{
                  marginRight: "15px",
                  marginLeft: "15px",
                }}>
                Teams: {sumMsTeams}
              </span>
              <span
                style={{
                  marginRight: "15px",
                  marginLeft: "15px",
                }}>
                {t("monthInteractions:others")}: {sumUndefined}
              </span>
              <div
                style={{
                  display: "flex",
                  marginLeft: "10px",
                  gap: "10px",
                }}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="default"
                  size="medium"
                  disabled={!valid}
                  onClick={fetchInteractionsMonth}
                  sx={{
                    height: "40px",
                    width: "130px",
                    borderRadius: "5px",
                  }}>
                  {t("monthInteractions:fetch")}
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="default"
                  size="medium"
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  onClick={() => {
                    handleExportRows(table.getPrePaginationRowModel().rows);
                  }}
                  sx={{
                    height: "40px",
                    width: "130px",
                    borderRadius: "5px",
                  }}>
                  {t("monthInteractions:download")}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <div style={{ maxWidth: "100%" }}>
        <ThemeProvider
          theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
          <MaterialReactTable table={table} />
        </ThemeProvider>
      </div>
    </div>
  );
};
export default MonthInteractions;
