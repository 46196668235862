import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Dialog,
  DialogActions,
  TextField,
  Typography,
  Button,
  FormHelperText,
  Alert,
} from "@mui/material";
import Transition from "../../shared/helper/transitionDialog";
import PropTypes from "prop-types";
import ChipInput from "../../shared/components/ChipInput";
import TextDnD from "../../shared/components/TextDnD/TextDnD.component";

const EditModal = ({
  intent,
  open,
  onClose,
  onSave,
  validateNoDuplicateName,
  validateNoDuplicatePhrases,
  deleteValidate,
}) => {
  const [editIntent, setEditIntent] = useState();
  const [errors, setErrors] = useState({});
  const [itsDefault, setItsDefault] = useState(false);
  const [displayName, setDisplayName] = useState("");

  useEffect(() => {
    //It checks if it is the default intent welcome intent
    //TODO: Ver posibilidad de agregar un booleano en intent "defaultwelcome" para solucionar el condicional
    setItsDefault(deleteValidate(intent?.name));
    //set the modal with the intent to edit
    if (intent) {
      setEditIntent(JSON.parse(JSON.stringify(intent)));
      setDisplayName(intent.name.replace(/-/g, " "));
    }
  }, [intent]);

  const { t } = useTranslation("intents");

  const hasErrors = useMemo(
    () => Object.keys(errors).some((x) => errors[x] !== ""),
    [errors],
  );

  const spacesToDashes = useCallback(
    (str) => {
      if (!str) return "";

      const result = str.replace(/\s+/g, "-");
      setDisplayName(str);

      return result;
    },
    [setDisplayName],
  );

  useEffect(() => {
    const temp = {};

    let duplicatePhrases = editIntent?.phrases
      ? validateNoDuplicatePhrases(editIntent?.phrases, editIntent?._id)
      : false;
    temp.name = editIntent?.name ? "" : t("errorNameRequired");
    temp.name =
      editIntent?.name &&
      validateNoDuplicateName(editIntent?.name, editIntent?._id)
        ? t("errorNameDuplicate")
        : temp.name;
    temp.phrases =
      editIntent?.phrases?.length > 0 ? "" : t("errorphrasesRequired");
    temp.phrases =
      editIntent?.phrases?.length > 0 && duplicatePhrases
        ? t("errorphrasesDuplicate", duplicatePhrases)
        : temp.phrases;

    for (const ans of editIntent?.response?.text ?? []) {
      if (ans.length > 10000) {
        temp.answer = t("intents:errorLengthValidation");
        break;
      } else {
        temp.answer = "";
      }
    }
    setErrors({ ...temp });
  }, [editIntent, t]);

  const handleNameChange = (e) => {
    const name = e.target.value;
    const newName = spacesToDashes(name);
    setDisplayName(name);
    setEditIntent({ ...editIntent, name: newName });
  };

  const handleClose = () => {
    setEditIntent(JSON.parse(JSON.stringify(intent)));
    setDisplayName(intent.name);
    onClose();
  };

  const isSaveDisabled = useMemo(() => {
    const hasQuickReplies = editIntent?.quickReplies?.length > 0;
    const hasLongAnswer = editIntent?.response?.text?.some(
      (text) => text.length > 1024
    );
    return hasQuickReplies && hasLongAnswer;
  }, [editIntent?.quickReplies, editIntent?.response?.text]);
  

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      TransitionComponent={Transition}>
      <Box p={2} sx={{ width: 600 }}>
        <Typography variant="h6" gutterBottom>
          {t("editIntent")}
        </Typography>
        <TextField
          id="modal-name"
          name="name"
          source="name"
          variant="standard"
          label={t("intents:name")}
          disabled={itsDefault}
          sx={{ width: "80%", marginBottom: 2, marginLeft: "10%" }}
          value={displayName}
          onChange={handleNameChange}
          error={errors.name !== "" && errors.name !== undefined}
          helperText={errors.name}
          fullWidth
        />
        <ChipInput
          id="edit-intent-name-phrases"
          name="phrases"
          source="phrases"
          label={t("intents:phrases")}
          sx={{ width: "80%", marginBottom: 2, marginLeft: "10%" }}
          value={editIntent?.phrases}
          updateProps={(e) => {
            setEditIntent({ ...editIntent, phrases: e });
          }}
          error={
            errors.phrases !== "" && errors.phrases !== undefined
              ? errors.phrases
              : ""
          }
        />
        <div style={{ width: "80%", marginBottom: 2, marginLeft: "10%" }}>
          <TextDnD
            id="edit-intent-name-answer"
            title={t("intents:answer")}
            disabled={itsDefault}
            items={editIntent?.response?.text}
            setItems={(e) => {
              let tempResponse = editIntent?.response;
              tempResponse.text = e;
              setEditIntent({ ...editIntent, response: tempResponse });
            }}></TextDnD>
          <FormHelperText error>
            {errors.answer ? errors.answer : ""}
          </FormHelperText>
          <Alert variant="filled" severity="info"
            sx={{ backgroundColor: "rgba(75, 139, 251, 1)", color: "white" }}>
            {t("messageAlert")}
          </Alert>
        </div>
      </Box>
      <DialogActions>
        <Button id="modal-cancel-button" onClick={handleClose}>
          {t("cancel")}
        </Button>
        <Button
          id="modal-save-button"
          variant="contained"
          color="primary"
          disabled={hasErrors || isSaveDisabled}
          onClick={() => onSave(editIntent)}>
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
EditModal.propTypes = {
  intent: PropTypes.shape({
    name: PropTypes.string,
    phrases: PropTypes.arrayOf(PropTypes.string),
    response: PropTypes.shape({
      text: PropTypes.arrayOf(PropTypes.string),
    }),
    _id: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  validateNoDuplicateName: PropTypes.func.isRequired,
  validateNoDuplicatePhrases: PropTypes.func.isRequired,
  deleteValidate: PropTypes.func.isRequired,
};

export default EditModal;
