/* eslint-disable no-undef */
import React, { useState, useEffect, useMemo } from "react";
import { Box, IconButton, Tooltip, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { tableLightTheme, tableDarkTheme } from "../shared/theming/table.theme";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { get, post, put, remove } from "../shared/http/httpService";
import { MaterialReactTable } from "material-react-table";
import EditModal from "./ClientModal.component";
import { DeleteRowModal } from "../shared/helper/tableHelper";
import ClientImportModal from "./ClientImportModal";

const host_api_gw = process.env.REACT_APP_API_GW;

const Clients = ({ updateClients }) => {
  //Seteo de variables necesarias para el funcionamiento
  const theme = useSelector((state) => state.theme);
  const { t, i18n } = useTranslation("clients");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [editRow, setEditRow] = useState(null);
  const [typeModal, setTypeModal] = useState(null);
  const [agents, setAgents] = useState([]);
  const [agentsForClient, setAgentsForClient] = useState([]);
  const [dataOld, setDataOld] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [endpointIE, setEndpointIE] = useState(false);
  const [iEnoAccess, setIEnoAccess] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [clientImport, setClientImport] = useState("");
  const [openImportModal, setOpenImportModal] = useState(false);
  const [userPermissions, setUserPermissions] = useState(false);
  let auth = useSelector((state) => state.auth);

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "clients" && permission.fullAccess === true,
    );
    let hasImportExport = user.permissions.some(
      (permission) => 
        (permission.name === "clientsExportImport" && permission.fullAccess === true),
    );
    let noAccessIE = user.permissions.some((p) => (p.name === "clientsExportImport" && p.fullAccess === null));
    setIEnoAccess(noAccessIE);
    setEndpointIE(hasImportExport);
    setUserPermissions(hasPermissions);
  };

  //Funciones de peticion de datos para tabla
  useEffect(() => {
    getAgents();
    getClients();
    getUserPermisions();
  }, [loading]);

  const getAgents = async () => {
    get("/agents/all")
      .then(async (data) => {
        setAgents(data);
        setLoading(false);
        data
          .filter((item) => item.agents === undefined)
          .forEach((item) => {
            enqueueSnackbar(
              t("errorGettingAgentsForClient", { client: item.client }),
              {
                variant: "error",
              },
            );
          });
      })
      .catch(() => {
        enqueueSnackbar(t("clients:errorGettingAgents"), {
          variant: "error",
        });
      });
  };

  const getClients = async () => {
    get("/clients")
      .then(async (data) => {
        setData(data);
      })
      .catch(() => {
        enqueueSnackbar(t("clients:errorGettingClients"), {
          variant: "error",
        });
      });
  };

  //Funcion para crear cliente

  const newClient = (client) => {
    const name = client.name;
    const display_name = client.displayName;
    const default_agent = client.defaultAgent;
    let error = "Error";
    let body = {
      name: name,
      display_name: display_name,
      default_agent: default_agent,
    };
    post("/clients", body)
      .then(() => {
        enqueueSnackbar(t("clients:clientAdded"), {
          variant: "success",
        });
        setTimeout(() => {
          updateClients();
          setLoading(!loading);
        }, 700);
      })
      .catch((e) => {
        error = `Error: ${e.message}`;
        enqueueSnackbar(error, {
          variant: "error",
        });
      });
  };

  //Funciones para editar cliente
  const openModal = (type, row) => {
    setModalOpen(!modalOpen);
    if (type === "new") {
      setTypeModal("new");
    } else if (type === "edit" && row) {
      setTypeModal("edit");
      setEditRow(row);
      agents.forEach((e) => {
        if (e.client === row.name) {
          setAgentsForClient(e.agents);
        }
      });
    }
  };

  const getAgentId = async (agent_name, client) => {
    const agentsOfClients = [];
    agents.forEach((e) => {
      if (e.client === client) {
        e.agents.forEach((agent) => agentsOfClients.push(agent));
      }
    });
    const agent = agentsOfClients.find(
      (element) => element.name === agent_name,
    );
    return agent ? agent._id : null;
  };

  const updateClient = async (values, row) => {
    const body = {
      _id: row._id,
      name: row.name,
      display_name: values.displayName,
      default_agent: values.defaultAgent,
      default_agent_id: await getAgentId(values.defaultAgent, row.name),
      default_agent_old: dataOld.default_agent,
      default_agent_old_id: await getAgentId(dataOld.default_agent, row.name),
    };
    put("/clients", body)
      .then((e) => {
        if (e === true) {
          enqueueSnackbar(t("clients:clientUpdated"), {
            variant: "success",
          });
          updateClients();
          getClients();
        } else if (e === false) {
          enqueueSnackbar(t("clients:featureDisabled"), {
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(t("clients:errorUpdateClients"), {
          variant: "error",
        });
      });
  };

  //Carga de datos en filas y columnas
  const columns = useMemo(
    () => [
      {
        header: t("clients:name"),
        accessorKey: "name",
        enableEditing: false,
        required: true,
      },
      {
        header: t("clients:displayName"),
        accessorKey: "display_name",
        required: true,
      },
      {
        header: t("clients:defaultAgent"),
        accessorKey: "default_agent",
        required: true,
      },
    ],
    [i18n.language],
  );

  const deleteClient = (row) => {
    remove("/clients", { _id: row._id, name: row.name, user: row.user })
      .then(async () => {
        enqueueSnackbar(t("clients:clientDeleted"), {
          variant: "success",
        });
        setLoading(!loading);
        updateClients();
      })
      .catch(() => {
        enqueueSnackbar(t("clients:errorDeleteClient"), {
          variant: "error",
        });
      });
  };

  //Funciones para botones de columnas (No edit, no delete)
  const getEndpoint = async (row) => {
    get("/clients/endpoint")
      .then((clientEndpoint) => {
        alert(`${clientEndpoint}/${row.name}/inbound`);
      })
      .catch(() => {
        enqueueSnackbar(t("clients:errorGettingClients"), {
          variant: "error",
        });
      });
  };

  const getDatabase = async (row) => {
    get("/file-download/download/" + row.name)
      .then(async () => {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = host_api_gw + "/file-download/download/" + row.name;
        // the filename you want
        a.download = "todo-1.json";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(
          host_api_gw + "/file-download/download/" + row.name,
        );
      })
      .catch((e) => {
        enqueueSnackbar(`${e}`, {
          variant: "error",
        });
      });
  };

  const exportDatabase = async (row) => {
    if (!endpointIE) {
      enqueueSnackbar(t("clients:exportPermission"), { variant: "error" });
      return;
    }

    get("/file-download/upload/" + row.name)
      .then(async () => {
        await getDatabase(row);
      })
      .catch((e) => {
        enqueueSnackbar(`${e}`, {
          variant: "error",
        });
      });
  };

  const handleOpenImportModal = () => {
    setOpenImportModal(true);
  };

  const handleCloseImportModal = () => {
    setOpenImportModal(false);
  };
 
  //Render
  return (
    <React.Fragment>
      <div>
        <Typography variant="h4" sx={{ mb: "1rem" }}>
          {t("clients:clients")}
        </Typography>
        <ThemeProvider
          theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
          <MaterialReactTable
            localization={
              i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN
            }
            displayColumnDefOptions={{
              "mrt-row-actions": {
                muiTableHeadCellProps: {
                  align: "center",
                },
                size: 50,
              },
            }}
            defaultColumn={{
              size: 260,
              align: "center",
            }}
            positionActionsColumn="last"
            columns={columns}
            data={data}
            enableColumnOrdering
            enableGlobalFilter={true}
            editDisplayMode="modal"
            enableEditing
            onEditingRowSave={updateClient}
            enableDelete
            renderRowActions={({ row }) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                }}>
                <Tooltip
                  arrow
                  placement="left"
                  title={t("clients:clientEndpoint")}>
                  <IconButton
                    id="endpoint-button"
                    onClick={() => getEndpoint(row.original)}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow placement="left"
                  title={ endpointIE
                    ? t("clients:exportDB")
                    : t("clients:noPermissionTooltip")}>
                  {iEnoAccess ? null : (
                    <span>
                      <IconButton
                        id="export-button"
                        onClick={() => exportDatabase(row.original)}
                        disabled={!endpointIE}>
                        <FileUploadOutlinedIcon />
                      </IconButton>
                    </span>
                  )}
                </Tooltip>
                <Tooltip
                  arrow
                  placement="left"
                  title={
                    endpointIE
                      ? t("clients:importDB")
                      : t("clients:noPermissionTooltip")
                  }>
                  {iEnoAccess ? null : (
                    <span>
                      <IconButton
                        id="import-button"
                        onClick={() => {
                          handleOpenImportModal();
                          setClientImport(row.original.name);
                        }}
                        disabled={!endpointIE}>
                        <FileDownloadOutlinedIcon style={{ fontSize: "24px" }} />
                      </IconButton>
                    </span>
                  )}
                </Tooltip>
                <Tooltip
                  arrow
                  placement="left"
                  title={
                    userPermissions
                      ? t("clients:editButton")
                      : t("clients:noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      id="edit-button"
                      onClick={() => {
                        setDataOld(row.original);
                        openModal("edit", row.original);
                      }}
                      disabled={!userPermissions}>
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    userPermissions
                      ? t("clients:deleteButton")
                      : t("clients:noPermissionTooltip")
                  }>
                  <span>
                    <IconButton
                      id="delete-button"
                      sx={{ color: "deleteIcon.color" }}
                      onClick={() => {
                        setDeleteModalOpen(!deleteModalOpen);
                        setEditRow(row.original);
                      }}
                      disabled={!userPermissions}>
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
            renderTopToolbarCustomActions={() => (
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  p: "0.5rem",
                  flexWrap: "wrap",
                }}>
                <Tooltip title={!userPermissions ? t("clients:disabledTooltip") : ""}>
                  <span>
                    <Button
                      className="mrt-create-new-account-button"
                      id="new-client-button"
                      onClick={() => openModal("new")}
                      variant="contained"
                      disabled={!userPermissions}>
                      {t("clients:addButton")}
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            )}
          />
        </ThemeProvider>
      </div>
      <DeleteRowModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={() => {
          deleteClient(editRow);
          setDeleteModalOpen(!deleteModalOpen);
        }}
        title={t("clientsModal:tittleDeleteModal")}
      />
      <EditModal
        open={modalOpen}
        agents={agents}
        onClose={() => {
          setModalOpen(false);
          setTimeout(() => {
            setTypeModal(null);
          }, 150);
        }}
        data={{
          name: editRow?.name,
          display_name: editRow?.display_name,
          default_agent: editRow?.default_agent,
          agents: agentsForClient,
        }}
        clients={data}
        onSave={(values) => {
          if (typeModal === "new") {
            newClient(values);
            setModalOpen(false);
          } else {
            updateClient(values, editRow);
            setModalOpen(false);
          }
        }}
        typeModal={typeModal}
      />
      <ClientImportModal
        open={openImportModal}
        onClose={() => {
          setOpenImportModal(false);
          handleCloseImportModal();
        }}
        client={clientImport}
        refreshClients={getClients}
        endpointIE={endpointIE}
      />
    </React.Fragment>
  );
};

export default Clients;
