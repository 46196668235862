import React, { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { withSnackbar } from "notistack";
import { useSnackbar } from "notistack";
import { withTranslation } from "react-i18next";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { get } from "../shared/http/httpService";
import { defaultAgent } from "../shared/helper/agentsHelper";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { orderAlphabeticallyWithAttribute } from "../shared/helper/orderAlphabetically";
import RefreshIcon from "@mui/icons-material/Refresh";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Editor from "@monaco-editor/react";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { tableLightTheme, tableDarkTheme } from "../shared/theming/table.theme";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";

const Page = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation("pages");
  const theme = useSelector((state) => state.theme);
  const [modalOpen, setModalOpen] = useState(false);
  const [transitionCode, setTransitionCode] = useState("");
  const [data, setData] = useState([]);
  const [accountNames, setAccountNames] = useState([]);
  const [accountSelected, setAccountSelected] = useState("");

  useEffect(() => {
    getPages();
  }, [accountSelected]);
  useEffect(() => {
    getAccountNames();
  }, []);

  useEffect(() => {
    getPages();
  }, []);

  const columns = useMemo(
    () => [
      {
        header: t("pages:name"),
        accessorKey: "name",
        enableEditing: false,
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 250,
      },
      {
        header: t("pages:response"),
        accessorKey: "response.responseMessage",
        enableEditing: false,
        size: 250,
      },
      {
        header: t("pages:transitions"),
        accessorKey: "transitions",
        Cell: ({ cell }) =>
          cell.getValue()?.map((item) => (
            <Button key={item}
              variant="outlined"
              onClick={() => {
                setModalOpen(true);
                setTransitionCode(item);
              }}>
              {t("pages:view")}
            </Button>
          )) || "No transitions",
        Edit: ({ cell}) =>
          cell.getValue()?.map((item) => (
            <Button key={item}
              variant="outlined"
              onClick={() => {
                setModalOpen(true);
                setTransitionCode(item);
              }}>
              Ver
            </Button>
          )) || "No transitions",
        muiTableHeadCellProps: {
          align: "left",
        },
      },
    ],
    [i18n.language],
  );

  const getAccountNames = () => {
    get("/essentials/data/agents")
      .then((data) => {
        orderAlphabeticallyWithAttribute(data.agents, "display_name");
        let default_agent = defaultAgent(data.agents);
        setAccountNames(data.agents);
        setAccountSelected(default_agent.name);
      })
      .catch(() => {
        enqueueSnackbar(t("pages:errorGettingAccountNames"), {
          variant: "error",
        });
      });
  };

  const getPages = () => {
    get("/pages?agent=" + accountSelected)
      .then((data) => {
        setData(data);
      })
      .catch(() => {
        enqueueSnackbar(t("pages:errorGettingThePages"), {
          variant: "error",
        });
      });
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme.darkTheme ? tableLightTheme : tableDarkTheme}>
        <Typography variant="h4" sx={{ mb: "1rem" }}>
          {t("pages:pages")}
        </Typography>
        <MaterialReactTable
          localization={
            i18n.language === "ES" ? MRT_Localization_ES : MRT_Localization_EN
          }
          columns={columns}
          data={data}
          enableColumnOrdering
          enableGlobalFilter={false}
          renderToolbarInternalActions={({ table }) => (
            <Box>
              <Tooltip arrow title={t("pages:tooltipRefresh")}>
                <IconButton
                  onClick={() => {
                    getPages();
                  }}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </Box>
          )}
          renderTopToolbarCustomActions={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              <InputLabel
                id="agent-label"
                sx={{
                  display: "inline",
                  height: "fit-content",
                  marginRight: "15px",
                  marginLeft: "10px",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}>
                {t("pages:agent")}
              </InputLabel>
              <FormControl variant="standard">
                <Select
                  id="combo-agent"
                  value={accountSelected}
                  onChange={(e) => {
                    setAccountSelected(e.target.value);
                    getPages();
                  }}
                  sx={{
                    height: "fit-content",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}>
                  {accountNames.map((item, index) => (
                    <MenuItem
                      name={item.display_name}
                      value={item.name}
                      key={index}
                      id={`menu-item-${index}`}>
                      {item.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        />
      </ThemeProvider>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="lg"
        fullWidth>
        <DialogTitle>
          {t("pages:view")} {t("pages:transitions")}
        </DialogTitle>
        <DialogContent>
          <Editor
            height="90vh"
            theme="vs-dark"
            value={JSON.stringify(transitionCode, true, 4)}
            language="json"
            options={{
              fontSize: 14,
              mouseWheelZoom: true,
              selectOnLineNumbers: true,
              roundedSelection: false,
              readOnly: true,
              cursorStyle: "line",
              automaticLayout: true,
              minimap: {
                enabled: true,
              },
              scrollBeyondLastLine: false,
              language: "json",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)}>
            {t("pages:close")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withTranslation()(withSnackbar(Page));
