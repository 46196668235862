import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const compareValues = (key, value1, value2) => {
  if (typeof value1 === "object" && typeof value2 === "object") {
    return JSON.stringify(value1) !== JSON.stringify(value2);
  }
  return value1 !== value2;
};

const QuickRepliesChanges = ({
  quickReplies = [],
  originalQuickReplies = [],
}) => {
  const { t } = useTranslation();
  return quickReplies.map((reply, index) => {
    const originalReply = originalQuickReplies[index] || {};

    return (
      <Box
        key={reply._id}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          borderRadius: "5px",
          backgroundColor: "background.default",
          maxWidth: "100%",
          flexWrap: "wrap",
          marginTop: 0.5,
          padding: 1,
          marginLeft: 2,
        }}>
        {Object.keys(reply).map((key) => {
          if (key === "_id") return null;
          return (
            <div key={key} style={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
                {t(key)}:
              </Typography>
              {compareValues(key, reply[key], originalReply[key]) ? (
                <>
                  <Typography
                    sx={{
                      marginLeft: 2,
                      fontSize: "14px",
                      color: "tracesName.delete",
                      textDecoration: "line-through",
                    }}>
                    {originalReply[key] !== undefined
                      ? originalReply[key].toString()
                      : "N/A"}
                  </Typography>
                  <Typography
                    sx={{
                      marginLeft: 2,
                      fontSize: "14px",
                      color: "tracesName.new",
                    }}>
                    {reply[key].toString()}
                  </Typography>
                </>
              ) : (
                <Typography sx={{ marginLeft: 2, fontSize: "14px" }}>
                  {reply[key].toString()}
                </Typography>
              )}
            </div>
          );
        })}
      </Box>
    );
  });
};

export default QuickRepliesChanges;
