import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import {
  validateEmpty,
  validateUrlRoute,
} from "../../../shared/helper/validations";
import PropTypes from "prop-types";

const AuthItem = ({
  auth,
  _id,
  arrayAuth,
  modalDeleteAuth,
  saveAuth,
  userPermissions,
}) => {
  const { t } = useTranslation("apiauths");
  const [editing, setEditing] = useState();
  const [edit, setEdit] = useState();
  const [editUsernameValue, setEditUsernameValue] = useState(false);
  const [editPasswordValue, setEditPasswordValue] = useState(false);
  const [errors, setErrors] = useState();
  const [save, setSave] = useState();
  const [isNew, setIsNew] = useState({});

  useEffect(() => {
    setEditing(auth.editing);
    setErrors({});
    let tempEdit;
    if (auth.editing) {
      if (auth.add) {
        tempEdit = {
          _id: auth._id,
          type: auth.type,
          payload: {
            username: {
              key: auth.payload.username.key,
              value: auth.payload.username.value,
            },
            password: {
              key: auth.payload.password.key,
              value: auth.payload.password.value,
            },
            loginRoute: auth.payload.loginRoute,
            tokenPrefix: auth.payload.tokenPrefix,
          },
          tokenName: auth.tokenName,
          new: auth.new,
          editing: auth.editing,
        };
        delete auth.add;
        delete arrayAuth[_id].add;
      } else {
        tempEdit = {
          _id: auth._id,
          type: edit?.type ?? auth.type,
          payload: {
            username: {
              key: edit?.payload?.username?.key ?? auth.payload.username.key,
              value:
                edit?.payload?.username?.value ?? auth.payload.username.value,
              editValue: false,
            },
            password: {
              key: edit?.payload?.password?.key ?? auth.payload.password.key,
              value:
                edit?.payload?.password?.value ?? auth.payload.password.value,
              editValue: false,
            },
            loginRoute: edit?.payload?.loginRoute ?? auth.payload.loginRoute,
            tokenPrefix: edit?.payload?.tokenPrefix ?? auth.payload.tokenPrefix,
          },
          tokenName: edit?.tokenName ?? auth.tokenName,
          new: edit?.new ?? auth.new,
          editing: auth.editing,
        };
      }
      setEdit(tempEdit);
    }
    if (auth.new) {
      const tempIsNew = {};
      if (
        !tempEdit.payload?.loginRoute ||
        tempEdit.payload?.loginRoute === ""
      ) {
        tempIsNew.loginRoute = true;
      }
      if (
        !tempEdit.payload?.username?.value ||
        tempEdit.payload?.username?.value === ""
      ) {
        tempIsNew.usernameValue = true;
      }
      if (
        !tempEdit.payload?.password?.value ||
        tempEdit.payload?.password?.value === ""
      ) {
        tempIsNew.passwordValue = true;
      }
      if (
        !tempEdit.payload?.tokenPrefix ||
        tempEdit.payload?.tokenPrefix === ""
      ) {
        tempIsNew.tokenPrefix = true;
      }
      if (!tempEdit.tokenName || tempEdit.tokenName === "") {
        tempIsNew.tokenName = true;
      }
      setIsNew(tempIsNew);
    } else {
      const tempIsNew = {};
      tempIsNew.loginRoute = false;
      tempIsNew.usernameValue = false;
      tempIsNew.passwordValue = false;
      tempIsNew.tokenPrefix = false;
      tempIsNew.tokenName = false;
      setIsNew(tempIsNew);
    }
  }, [auth]);

  useEffect(() => {
    if (editing) {
      validate();
    } else {
      setErrors({});
    }
  }, [edit]);

  const validate = () => {
    const tempError = {};
    tempError.loginRoute = validateLoginAuth();
    if (tempError.loginRoute === "") {
      delete tempError.loginRoute;
    }
    if (validateEmpty(edit.payload.username.key)) {
      delete tempError.usernameKey;
    } else {
      tempError.usernameKey = t("errorEmpty");
    }

    if (editUsernameValue) {
      if (validateEmpty(edit.payload.username.value)) {
        delete tempError.usernameValue;
      } else {
        if (!isNew.usernameValue) {
          tempError.usernameValue = t("errorEmpty");
        }
      }
    }

    if (validateEmpty(edit.payload.password.key)) {
      delete tempError.passwordKey;
    } else {
      tempError.passwordKey = t("errorEmpty");
    }

    if (editPasswordValue) {
      if (validateEmpty(edit.payload.password.value)) {
        delete tempError.passwordValue;
      } else {
        if (!isNew.passwordValue) {
          tempError.passwordValue = t("errorEmpty");
        }
      }
    }

    if (Object.keys(tempError).length > 0) {
      setSave(false);
    } else if (isNew.loginRoute || isNew.usernameValue || isNew.passwordValue) {
      setSave(false);
    } else {
      setSave(true);
    }
    setErrors(tempError);
  };

  const validateLoginAuth = () => {
    if (!isNew.loginRoute) {
      if (!edit?.payload.loginRoute || edit.payload.loginRoute === "") {
        return t("errorEmptyRoute");
      } else if (!validateUrlRoute(edit.payload.loginRoute)) {
        return t("errorRoute");
      } else if (arrayAuth?.some((e) => ((e.payload.loginRoute === edit?.payload?.loginRoute && (e._id !== edit._id)) ))) {
        return t("errorRouteExists");
      }
    }
    return "";
  };

  const handleEditButton = () => {
    if (!editing) {
      setEdit({
        _id: auth._id,
        type: auth.type,
        payload: {
          username: {
            key: auth.payload.username.key,
            value: "",
          },
          password: {
            key: auth.payload.password.key,
            value: "",
          },
          loginRoute: auth.payload.loginRoute,
          tokenPrefix: auth.payload.tokenPrefix,
        },
        new: auth.new,
        editing: auth.editing,
      });
    }
    setEditing(true);
  };

  const handleEditInput = (value, fieldName) => {
    let editAuth = { ...edit };
    const tempIsNew = { ...isNew };
    if (fieldName === "type") {
      editAuth[fieldName] = value;
    } else if (fieldName === "loginRoute") {
      if (isNew.loginRoute) {
        tempIsNew.loginRoute = false;
      }
      editAuth.payload.loginRoute = value;
    } else if (fieldName.includes("username")) {
      if (fieldName.includes("key") || fieldName.includes("Key")) {
        editAuth.payload.username.key = value;
      } else if (fieldName.includes("value") || fieldName.includes("Value")) {
        setEditUsernameValue(true);
        if (isNew.usernameValue) {
          tempIsNew.usernameValue = false;
        }
        editAuth.payload.username.value = value;
      }
    } else if (fieldName.includes("password")) {
      if (fieldName.includes("key") || fieldName.includes("Key")) {
        editAuth.payload.password.key = value;
      } else if (fieldName.includes("value") || fieldName.includes("Value")) {
        setEditPasswordValue(true);
        if (isNew.passwordValue) {
          tempIsNew.passwordValue = false;
        }
        editAuth.payload.password.value = value;
      }
    }
    if (fieldName === "tokenPrefix") {
      if (isNew.tokenPrefix) {
        tempIsNew.tokenPrefix = false;
      }
      editAuth.payload.tokenPrefix = value;
    }
    if (fieldName === "tokenName") {
      if (isNew.tokenName) {
        tempIsNew.tokenName = false;
      }
      editAuth.tokenName = value;
    }
    setIsNew(tempIsNew);
    setEdit(editAuth);
  };

  const handleSaveEdit = () => {
    const tempEdit = edit;
    if (tempEdit.payload.username.value === "**********") {
      delete tempEdit.payload.username.value;
    }
    if (tempEdit.payload.password.value === "**********") {
      delete tempEdit.payload.password.value;
    }
    saveAuth(tempEdit);
    setEdit({});
    setEditing(false);
    setEditUsernameValue(false);
    setEditPasswordValue(false);
  };

  const handleCancelEdit = () => {
    const payload = auth.payload;
    setEditing(false);
    if (auth.new) {
      setEdit({});
      modalDeleteAuth(auth);
    } else {
      const originalauth = {
        type: auth.type,
        payload: {
          username: {
            key: payload.username.key,
            value: payload.username.value,
          },
          password: {
            key: payload.password.key,
            value: payload.password.value,
          },
          loginRoute: payload.loginRoute,
          tokenPrefix: payload.tokenPrefix,
        },
      };
      setEdit(originalauth);
    }
  };

  const renderPayload = (auth) => {
    const payload = editing ? edit.payload : auth.payload;
    const tokenName = editing ? edit.tokenName : auth.tokenName;
    return (
      <Box>
        <TextField
          id="nameType"
          value={auth.type}
          onChange={(e) => handleEditInput(e.target.value, "type")}
          label={t("nameType")}
          disabled={true}
          variant="standard"
          sx={{ marginTop: "8px" }}
        />
        <TextField
          id="loginRoute"
          value={payload.loginRoute}
          onChange={(e) => handleEditInput(e.target.value, "loginRoute")}
          label={t("loginauth")}
          error={errors?.loginRoute ? true : false}
          helperText={errors?.loginRoute}
          disabled={!editing}
          variant="standard"
          fullWidth
          sx={{ marginTop: "8px" }}
        />
        <TextField
          id="usernameKey"
          value={payload.username.key}
          onChange={(e) => handleEditInput(e.target.value, "usernameKey")}
          label={t("usernameKey")}
          error={errors?.usernameKey ? true : false}
          helperText={errors?.usernameKey}
          disabled={!editing}
          variant="standard"
          fullWidth
          sx={{ marginTop: "8px" }}
        />

        <TextField
          id="usernameValue"
          value={!editing ? "**********" : payload.username.value}
          onChange={(e) => handleEditInput(e.target.value, "usernameValue")}
          label={t("usernameValue")}
          error={errors?.usernameValue ? true : false}
          helperText={errors?.usernameValue}
          disabled={!editing}
          variant="standard"
          fullWidth
          sx={{ marginTop: "8px" }}
        />
        <TextField
          id="passwordKey"
          value={payload.password.key}
          onChange={(e) => handleEditInput(e.target.value, "passwordKey")}
          label={t("passwordKey")}
          error={errors?.passwordKey ? true : false}
          helperText={errors?.passwordKey}
          disabled={!editing}
          variant="standard"
          fullWidth
          sx={{ marginTop: "8px" }}
        />
        <TextField
          id="passwordValue"
          value={!editing ? "**********" : payload.password.value}
          onChange={(e) => handleEditInput(e.target.value, "passwordValue")}
          label={t("passwordValue")}
          error={Boolean(errors?.passwordValue)}
          helperText={errors?.passwordValue}
          fullWidth
          disabled={!editing}
          display={!editing}
          variant="standard"
          sx={{ marginTop: "8px" }}
        />
        <TextField
          id="tokenPrefix"
          value={payload.tokenPrefix}
          onChange={(e) => handleEditInput(e.target.value, "tokenPrefix")}
          label={t("tokenPrefix")}
          error={errors?.tokenPrefix ? true : false}
          helperText={errors?.tokenPrefix}
          disabled={!editing}
          variant="standard"
          fullWidth
          sx={{ marginTop: "8px" }}
        />
        <TextField
          id="tokenName"
          value={tokenName}
          onChange={(e) => handleEditInput(e.target.value, "tokenName")}
          label={t("tokenName")}
          error={!!errors?.tokenName}
          helperText={errors?.tokenName}
          disabled={!editing}
          variant="standard"
          fullWidth
          sx={{ marginTop: "8px" }}
        />
      </Box>
    );
  };

  const renderButtonsEdit = (index) => {
    return (
      <Box
        sx={{
          marginTop: "5px",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}>
        <Button
          id={`saveEditButton_${index}`}
          variant="contained"
          disabled={!save}
          onClick={() => handleSaveEdit(index)}
          sx={{ margin: "8px" }}>
          {t("saveEditButon")}
        </Button>
        <Button
          id={`cancelEditButton_${index}`}
          variant="contained"
          onClick={() => handleCancelEdit(index)}
          sx={{ margin: "8px" }}>
          {t("cancelEditButon")}
        </Button>
      </Box>
    );
  };

  return (
    <Grid
      container
      columns={7}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}>
      <Grid item md={5}>
        {renderPayload(auth)}
      </Grid>
      <Grid
        item
        xs={1}
        rows={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}>
        <Grid
          item
          xs={2}
          columns={24}
          sx={{ display: "flex", flexDirection: "row" }}>
          <Grid
            item
            xs={8}
            sx={{ display: "flex", flexDirection: "row", marginRight: "20%" }}>
            <Tooltip
              title={
                userPermissions ? t("editTooltip") : t("noPermissionMessage")
              }
              sx={{
                display: "flex",
                "&:hover": { color: "#4e8cff" },
              }}>
              <span>
                <IconButton
                  id="iconEdit"
                  disabled={!userPermissions}
                  onClick={() => handleEditButton()}
                  sx={{ height: "50%" }}>
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={8}
            sx={{ display: "flex", flexDirection: "row", marginLeft: "20%" }}>
            <Tooltip
              title={
                userPermissions ? t("deleteTooltip") : t("noPermissionMessage")
              }
              sx={{
                display: "flex",
                "&:hover": { color: "#4e8cff" },
              }}>
              <span>
                <IconButton
                  id="iconDelete"
                  disabled={!userPermissions}
                  onClick={() => modalDeleteAuth(auth)}
                  sx={{ height: "50%" }}>
                  <Delete
                    sx={{
                      color: userPermissions ? "deleteIcon.color" : "grey",
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {editing ? renderButtonsEdit(_id) : null}
    </Grid>
  );
};

AuthItem.propTypes = {
  auth: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    payload: PropTypes.shape({
      username: PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired,
      password: PropTypes.shape({
        key: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired,
      loginRoute: PropTypes.string.isRequired,
      tokenPrefix: PropTypes.string.isRequired,
    }).isRequired,
    tokenName: PropTypes.string.isRequired,
    new: PropTypes.bool.isRequired,
    editing: PropTypes.bool.isRequired,
    add: PropTypes.bool,
  }).isRequired,
  _id: PropTypes.string.isRequired,
  arrayAuth: PropTypes.array.isRequired,
  modalDeleteAuth: PropTypes.func.isRequired,
  saveAuth: PropTypes.func.isRequired,
  userPermissions: PropTypes.object.isRequired,
};

export default AuthItem;
