import React, { Fragment, useEffect } from "react";
import TextField from "@mui/material/TextField";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function EdgeCleanParams({ ...props }) {
  const [cleanParams, setCleanParams] = React.useState([]);
  const { t } = useTranslation("edge");

  useEffect(() => {
    if (Array.isArray(props.cleanParams)) {
      setCleanParams(props.cleanParams);
    }
  }, [props.cleanParams]);

  useEffect(() => {}, [cleanParams]);

  function handleAddCleanParams() {
    let current = [...cleanParams];
    current.push("");
    setCleanParams(current);
    props.cleanParamsUpdate(current);
  }

  function handleRemoveCleanParams() {
    if (cleanParams.length > 0) {
      let current = [...cleanParams];
      current.pop("");
      setCleanParams(current);
      props.cleanParamsUpdate(current);
    }
  }

  function updateCleanParams(e, index) {
    let current = [...cleanParams];
    current[index] = e.target.value;
    setCleanParams(current);
    props.cleanParamsUpdate(current);
  }

  return (
    <Fragment>
      <div className="clean-params">
        <h3 className="clean-params-title">{t("cleanParams")}</h3>
        <div className="clean-params-buttons">
          <Tooltip
            title={
              !props.userPermissions
                ? t("noPermissionTooltip")
                : t("tooltipParamsAdd")
            }>
            <span>
              <IconButton
                disabled={!props.userPermissions}
                onClick={handleAddCleanParams}
                style={{ cursor: "pointer" }}>
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              !props.userPermissions
                ? t("noPermissionTooltip")
                : t("tooltipParamsDelete")
            }>
            <span>
              <IconButton
                disabled={!props.userPermissions}
                onClick={handleRemoveCleanParams}
                style={{ cursor: "pointer" }}>
                <RemoveIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
        {cleanParams?.map((item, index) => (
          <div key={index} className="clean-params-header">
            <Tooltip
              title={!props.userPermissions ? t("noPermissionTooltip") : ""}>
              <TextField
                className="clean-params-name"
                label={t("cleanParameter") + " " + (index + 1)}
                variant="standard"
                multiline
                disabled={!props.userPermissions}
                fullWidth
                defaultValue={item ? item : ""}
                onChange={(e) => updateCleanParams(e, index)}
              />
            </Tooltip>
          </div>
        ))}
      </div>
    </Fragment>
  );
}
